import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { TokenStorage } from "@/store/tokenStorage";
import { ElNotification } from "element-plus"
import VdTransportPage from '@/views/vdTransport/index.vue'
import AnalyticsPage from '@/views/analytics/index.vue'
import TicketsPage from '@/views/documentation/tickets/index.vue'
import SmartCardsPage from '@/views/documentation/smartCards/index.vue'
import SlipsPage from '@/views/documentation/slips/index.vue'
import ReceiptsPage from '@/views/documentation/receipts/index.vue'
import ShiftsPage from '@/views/documentation/shifts/index.vue'
import SbpPaymentsPage from '@/views/documentation/sbpPayments/index.vue'
import DrivesPage from '@/views/references/drivers/index.vue'
import SbpPage from '@/views/references/sbp/index.vue'
import CreateSbp from '@/views/references/sbp/modals/CreateSbp.vue'
import EditSbp from '@/views/references/sbp/modals/EditSbp.vue'
import RoutesPage from '@/views/references/routes/index.vue'
import CreateRoute from '@/views/references/routes/modals/CreateRoute.vue'
import EditRoute from '@/views/references/routes/modals/EditRoute.vue'
import OptionsRoute from '@/views/references/routes/modals/OptionsRoute.vue'
import PriceRoute from '@/views/references/routes/modals/PriceModal.vue'
import StopsGroupsRoute from '@/views/references/routes/modals/StopsGroupModal.vue'
import VehiclesPage from '@/views/references/vehicles/index.vue'
import StopsPage from '@/views/references/stops/index.vue'
import LicensesPage from '@/views/settings/licenses/index.vue'
import BidsPage from '@/views/settings/regRequests/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'vdTransport',
    component: VdTransportPage
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: AnalyticsPage
  },
  {
    path: '/documentation/receipts',
    name: 'receipts',
    component: ReceiptsPage,
  },
  {
    path: '/documentation/slips',
    name: 'slips',
    component: SlipsPage,
  },
  {
    path: '/documentation/tickets',
    name: 'tickets',
    component: TicketsPage
  },
  {
    path: '/documentation/cards',
    name: 'smartCards',
    component: SmartCardsPage
  },
  {
    path: '/documentation/shifts',
    name: 'shifts',
    component: ShiftsPage
  },
  {
    path: '/references/drivers',
    name: 'drives',
    component: DrivesPage
  },
  {
    path: '/references/routes',
    name: 'routes',
    component: RoutesPage,
    children: [
      {
        path: 'create',
        component: CreateRoute,
      },
      {
        path: 'edit/:id',
        component: EditRoute,
      },
      {
        path: 'options/:id',
        component: OptionsRoute,
      },
      {
        path: 'price/:id',
        component: PriceRoute,
      },
      {
        path: 'stops/groups/:id',
        component: StopsGroupsRoute,
      }
    ],
  },
  {
    path: '/references/stops',
    name: 'stops',
    component: StopsPage
  },
  {
    path: '/references/vehicles',
    name: 'vehicles',
    component: VehiclesPage
  },
  {
    path: '/references/sbp',
    name: 'sbp',
    component: SbpPage,
    children: [
      {
        path: 'create',
        component: CreateSbp,
      },
      {
        path: 'edit/:id',
        component: EditSbp,
      },
    ],
  },
  {
    path: '/documentation/sbp/payments',
    name: 'sbpPayments',
    component: SbpPaymentsPage
  },
  {
    path: '/settings/license',
    name: 'licenses',
    component: LicensesPage
  },
  {
    path: '/settings/bids',
    name: 'bids',
    component: BidsPage
  },
  {
    path: '/settings/tcot',
    name: 'tcot',
    component: () => import('@/views/settings/tcot/index.vue')
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: VdTransportPage 
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!TokenStorage.isAuthenticated() && to.path !== '/') {
    ElNotification.error({
      title: "Доступ запрещен",
      message: "Нужно авторизоваться",
    });
    router.push("/");
  }

  next();
});

export default router
