
import { Options, Vue } from 'vue-class-component';
import { Watch, Prop } from "vue-property-decorator";
import StopStore from "@/store/references/stops/index";
import { Tree } from "@/store/references/stops/interfaces";

const defaultUpdateForm = JSON.stringify({
  name: '',
  id: '',
  stopGroupId: '',
  parentStopGroupId: '',
  type: ''
});

@Options({})

export default class Update extends Vue {
  show = false;
  updateForm = JSON.parse(defaultUpdateForm)

  @Prop() current!: Tree;

  @Watch('current')
  onCurrentChanged(val: Tree) {
    this.updateForm = {
      ...val,
      type: val.children ? 'group' : 'stop'  
    }
  } 

  open() {
    this.show = true;
    this.updateForm = {
      ...this.current,
      type: this.current.children ? 'group' : 'stop'  
    }
  }
  
  async saveUpdate(){    
    if(this.updateForm.type === 'stop'){
      const response = await StopStore.updateStop({
        id: this.updateForm.id, 
        params: this.updateForm
      })
      if(response){
        this.$emit("submit");
        this.handleClose()
      }
    }
    if(this.updateForm.type === 'group'){
      const response = await StopStore.updateStopGroup({
        id: this.updateForm.id, 
        params: this.updateForm
      })
      if(response){
        this.$emit("submit");
        this.handleClose()
      }
    }
    this.current = {} as Tree
  }

  handleClose(){
    this.show = false
    this.refreshForm()
  }

  refreshForm() {
    this.updateForm = JSON.parse(defaultUpdateForm);
  }
}
