
import { Options, Vue } from 'vue-class-component';
import { Watch, Prop } from "vue-property-decorator";
import DriverStore from "@/store/references/drivers/index";
import { IDriver } from "@/store/references/drivers/interfaces";

const defaultForm = JSON.stringify({
  name: null,
  phone: null,
  birthDate: null,
});

@Options({})

export default class EditDriver extends Vue {
  show = false;
  form = JSON.parse(defaultForm);

  @Prop() driver!: IDriver;

  @Watch('driver')
  onDriverChanged(val: IDriver) {
    this.form = {...val}
  } 

  open() {
    this.show = true;
  }

  async saveDriver(){
    if(this.form.name === "") this.form.name = null
    if(this.form.phone === "") this.form.phone = null
    if(this.form.birthDate === "") this.form.birthDate = null

    const response = await DriverStore.updateDriver({
      id: this.driver.id, 
      params: this.form
    })
    
    if(response){
      this.$emit("submit");
      this.show = false;
      this.refreshForm()
    }
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }
}
