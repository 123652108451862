
import { Options, Vue } from 'vue-class-component';
import { Watch } from "vue-property-decorator";
import RouteStore from "@/store/references/routes/index";
import StopStore from "@/store/references/stops/index";
import { IStopsFromRoute, IStopsId } from "@/store/references/routes/interfaces";
import { IGroup, IStop, Tree } from '@/store/references/stops/interfaces';
import { ref } from 'vue'
import type { ElTree } from 'element-plus'
import {
    ArrowUp,
    ArrowDown,
    DArrowRight,
    FolderOpened,
    Folder,
    ArrowRight,
    ArrowLeft,
    RefreshRight,
    DocumentChecked,
} from '@element-plus/icons-vue'
import { DropType } from 'element-plus/es/components/tree/src/tree.type';
import router from '@/router';

const defaultForm = JSON.stringify({
  name: "",
  typeId: "",
});

interface IDataTree {
  data: Tree
}

@Options({
  components: {
      ArrowUp,
      ArrowDown,
      DArrowRight,
      FolderOpened,
      Folder,
      ArrowRight,
      ArrowLeft,
      RefreshRight,
      DocumentChecked,
    },
})

export default class EditRoute extends Vue {
  loading = false;
  disablMoveGroups = true
  disablMoveStops = true
  disablMove = true
  renderKey = 0
  show = false;
  expandAll = false;
  full = false
  checkedKey: string[] = []
  form = JSON.parse(defaultForm);
  stopsFromRoute: IStopsFromRoute[] = []
  stopGroups: IGroup[] = []
  treeRefGroup: any = ref<InstanceType<typeof ElTree>>()
  treeRefStops: any = ref<InstanceType<typeof ElTree>>()
  id = ''

  @Watch("$route.params.id")
  onIdChanged(id: string) {
    if(id){
      this.show = true;
      this.init()
      this.id = id
    }
  } 

  mounted(){
    this.id = this.$route.params.id as string
    this.show = true;
    this.init()
  }

  async init(){
    this.initData()
  }

  async initData(){
    this.loading = true;

    this.stopGroups = await StopStore.getStopsGroups({ includeStops: true })
    this.stopsFromRoute = await RouteStore.getStopsListFromRoute(this.id)

    this.initStopGroup(this.stopsFromRoute, false)
    this.initStopGroup(this.stopGroups, true)

    this.renderKey++
    this.loading = false;
  }

  initStopGroup(array: Tree[] | IGroup[], isSplise: boolean){
    let i = 0;
    while(i < array.length){
      isSplise && this.stopsFromRoute.map(stop => stop.id).includes(array[i].id as string)
        ? array.splice(i, 1)
        : i++
    }

    array.forEach(child => {
      child.label = child.name
      if(child.children?.length) this.initStopGroup(child.children, isSplise)
    })
  }

  moveToStops() {
    this.stopsFromRoute = this.stopsFromRoute.concat(this.treeRefGroup.getCheckedNodes(false, false).filter((group: IGroup) => !group.children))
    this.initStopGroup(this.stopGroups, true)
  }

  async moveToGroups() {
    let i = 0;
    while(i < this.stopsFromRoute.length){
      this.treeRefStops.getCheckedNodes(false, false).map((stop: IStop) => stop.id).includes(this.stopsFromRoute.map(stop => stop.id)[i])
        ? this.stopsFromRoute.splice(i, 1)
        : i++
    }
    this.stopGroups = await StopStore.getStopsGroups({ includeStops: true })
    this.initStopGroup(this.stopGroups, true)
  }

  handleCheckChange(){
    this.disablMove = this.treeRefStops && this.treeRefStops.getCheckedNodes(false, false).length === 1 ? false : true
    this.disablMoveGroups = this.treeRefStops && this.treeRefStops.getCheckedNodes(false, false).length ? false : true
    this.disablMoveStops = this.treeRefGroup && this.treeRefGroup.getCheckedNodes(false, false).length ? false : true
  }

  allowDrop(draggingNode: Node, dropNode: IDataTree, type: DropType & 'none'){ //разрешено ли перетаскивание
    if(type === 'inner') return false
    return true
  }

  async saveStopsList(){
    const updateStops: IStopsId[] = []

    this.stopsFromRoute.forEach((stops) => {
      updateStops.push({
        stopId: stops.id
      })  
    })

    await RouteStore.fullUpdateStopsList({
      id: this.id, 
      params: updateStops
    })

    await RouteStore.updateStopsList({
      id: this.id, 
      params: updateStops,
    })

    this.initData()
  }

  moveStop(direction: string){
    const index = this.stopsFromRoute.findIndex((stop, key) => stop.id === this.treeRefStops.getCheckedNodes(false, false)[0].id)
    
    if(direction === "up" && index > 0) [this.stopsFromRoute[index], this.stopsFromRoute[index-1]] = [this.stopsFromRoute[index-1], this.stopsFromRoute[index]];
 
    if(direction === "down" && index < Object.keys(this.stopsFromRoute).length - 1) [this.stopsFromRoute[index], this.stopsFromRoute[index+1]] = [this.stopsFromRoute[index+1], this.stopsFromRoute[index]];

    this.checkedKey = [this.treeRefStops.getCheckedNodes(false, false)[0].id]
    this.renderKey++
  }

  width = "700px"
  screenWidth = window.screen.width

  created() {
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.screenWidth = window.innerWidth;
    this.screenWidth < 600
      ?  this.width = "600px"
      :  this.width = "900px"  
  }

  close(){
    router.push('/references/routes')
  }
}
