
import { ref } from 'vue'
import { Options, Vue } from 'vue-class-component';
import SbpStore from "@/store/references/sbp/index";
import router from '@/router';
import {
    UploadFilled
} from '@element-plus/icons-vue'
import UploadInstance from 'element-plus'

const defaultForm = JSON.stringify({
  name: null,
  typeId: "",
  connectionParams: {
    merchantId: null,
    secretKey: null,
    clientId: null,
    clientSecret: null,
    certPassword: null,
    terminalId: null,
  }
});

@Options({
  components: {
      UploadFilled
    },
})
export default class CreateSbp extends Vue {
  show = false;
  form = JSON.parse(defaultForm);
  upload: any = ref<typeof UploadInstance>()
  file: any = ''

  mounted() {
    this.show = true;
  }

  async addSbp(){
    const response = await SbpStore.addSbp(this.form)
    if(response){
      const sbpList = await SbpStore.getSbpList()
      this.attachCertificate(sbpList[0].id)
      this.show = false;
      this.refreshForm()
    }
  }

  async getSbpList() {
    await SbpStore.getSbpList({
      page: Number(this.$route.query.page) || 1,
      limit: Number(this.$route.query.limit) || 10,
    });
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }

  close(){
    this.show = false;
    this.refreshForm()
    router.push('/references/sbp')
  }

  async attachCertificate(id: string) {
    const file = (Object.assign({}, this.upload?.uploadFiles[0]))?.raw
    if(!file) return
    const certificate = new FormData();
    certificate.append("cert", file, file.name);
    await SbpStore.attachCertificate({
      id: id, 
      cert: certificate
    })
  }
}
