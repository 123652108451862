
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import LicenseStore from "@/store/settings/licenses/index";
import { ILicense } from '@/store/settings/licenses/interfaces';
import { ElMessageBox } from 'element-plus';

@Options({
  components: {}
})
export default class EditLicense extends Vue {
  show = false;
  license = {} as ILicense

  @Prop() id!: string;
  @Prop() status!: string;

  @Watch('id')
  onIdChanged() {
    this.getLicenseById()
  } 
  
  open() {
    this.show = true;
  }

  async getLicenseById(){
    this.license = await LicenseStore.getLicenseBilling(this.id);
  }

  async updateLicense(){
    const response = await LicenseStore.updateLicenseBilling({
      id: this.license.id,
      params: {
        name: this.license.name,
      }
    });
    if(response) this.handleClose()
  }

  async pauseLicense() { //приостановить
    ElMessageBox.confirm(
      `Вы действительно хотите приостановить лицензию "${this.license.name}"?`,
      "Приостановление лицензии",
      {
        confirmButtonText: "Приостановить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--warning",
        type: "warning",
      }
    ).then(async () => {
      await LicenseStore.pauseLicense(this.license.licenseServiceInfo.id)
    })
  }

  async prolongateLicense() { // продлить
    ElMessageBox.confirm(
      `Вы действительно хотите продлить лицензию "${this.license.name}"?`,
      "Продление лицензии",
      {
        confirmButtonText: "Продлить",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--success",
        type: "success",
      }
    ).then(async () => {
      await LicenseStore.prolongateLicense(this.license.licenseServiceInfo.id) 
    })
  }

  async deactivateLicense() { //деактивировать
    ElMessageBox.confirm(
      `Вы действительно хотите деактивировать лицензию "${this.license.name}"?
      После совершения данного действия лицензия не подлежит восстановлению.`,
      "Деактивация лицензии",
      {
        confirmButtonText: "Деактивировать",
        cancelButtonText: "Отмена",
        center: true,
        confirmButtonClass: "el-button--danger",
        type: "error",
      }
    ).then(async () => {
      await LicenseStore.deactivateLicense(this.license.licenseServiceInfo.id) 
    })
  }

  handleClose() {
    this.show  = false
    this.$emit("submit");
  }
}
