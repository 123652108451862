
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SlipStore from "@/store/documentation/slips/index";
import { View } from '@element-plus/icons'
import TicketBlock from "@/views/documentation/tickets/modals/ticketBlock.vue"
import { ISlip } from '@/store/documentation/slips/interfaces';

@Options({
  components: {
    TicketBlock,
    View
  }
})
export default class SlipBlock extends Vue {
  show = false;
  slip = {} as ISlip[]
  ticketId = null
  @Prop() id!: string;

  @Watch('id')
  onIdChanged() {
    this.getSlipById()
  } 
  
  open() {
    this.show = true;
  }

  async getSlipById(){
    this.slip = await SlipStore.getSlip(this.id);
  }

  handleActions(event: any) {
    if (event.name === "OpenTicketBlock"){ 
      this.ticketId = event.row;
      const modal: any = this.$refs["TicketBlock"];
      modal.open();
    }
  }
}
