
import { Options, Vue } from 'vue-class-component';
import VehiclStore from "@/store/references/vehicles/index";
import { IVehicle, IVehiclListSort } from "@/store/references/vehicles/interfaces";
import {
    RefreshRight,
    Setting,
    Plus,
    Search,
} from '@element-plus/icons-vue'
import CreateVehicl from "@/views/references/vehicles/modals/CreateVehicl.vue";
import EditVehicl from "@/views/references/vehicles/modals/EditVehicl.vue";
import { ElMessageBox } from 'element-plus';
import { ISortDirection  } from "@/interfaces";

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      CreateVehicl,
      EditVehicl,
      RefreshRight,
      Setting,
      Plus,
      Search
    },
})
export default class Vehicles extends Vue {
  loading = false;
  vehiclInfo = {} as IVehicle

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  mounted() {
    this.init();
  }

  async init() {
    await this.getVehiclList()
  }

  async getVehiclList() {
    this.loading = true;
    await VehiclStore.getVehiclesList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
    });
    this.loading = false;
  }

  async deleteVehicl(id: string){
    await VehiclStore.deleteVehicl(id)
    this.getVehiclList()
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getVehiclList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getVehiclList();
  }

  handleActions(event: {name: string, row: IVehicle}) {
    if (event.name === "CreateVehicl"){ 
      const modal: any = this.$refs["CreateVehicl"];
      modal.open();
    }
    if (event.name === "OpenEditVehicl"){ 
      this.vehiclInfo = event.row
      const modal: any = this.$refs["EditVehicl"];
      modal.open();
    }
  }

  get vehiclesList() {
    return VehiclStore.vehiclList;
  }

  async changeSort(e: {
    column: string;
    prop: keyof IVehiclListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IVehiclListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getVehiclList();
  }

  async showMessage(vehicl: IVehicle){
    ElMessageBox.confirm(
      `Вы действительно хотите удалить транспортное средство "${vehicl.model}" с номером: ${vehicl.licensePlate}?`,
      "Внимание!",
      {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      await this.deleteVehicl(vehicl.id)
    });
  }
}
