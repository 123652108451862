
import { Options, Vue } from 'vue-class-component';
import RegRequestStore from "@/store/settings/regRequests/index";
import {
    RefreshRight,
    Delete,
} from '@element-plus/icons'
import { ISortDirection } from '@/interfaces';
import { IRegRequest, IRegRequestListSort } from '@/store/settings/regRequests/interfaces';
import { ElMessageBox } from 'element-plus';

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
        RefreshRight,
        Delete,
    },
})
export default class RegRequests extends Vue {
  loading = false;
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  mounted() {
    this.init();
  }

  async init() {
    await this.getRegRequestList()
  }

  async getRegRequestList() {
    this.loading = true;
    await RegRequestStore.getRegRequestsList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
    });
    this.loading = false;
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getRegRequestList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getRegRequestList();
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof IRegRequestListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IRegRequestListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getRegRequestList();
  }

  async deleteRegRequest(regRequest: IRegRequest){
    ElMessageBox.confirm(
      `Вы действительно хотите удалить заявку на активацию?`,
      "Внимание!",
      {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      RegRequestStore.deleteRegRequest(regRequest.id)
      this.getRegRequestList()
    });
  }

  get regRequestsList() {
    return RegRequestStore.regRequestList;
  }
}
