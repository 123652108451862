
import { Options, Vue } from 'vue-class-component';
import LicenseStore from "@/store/settings/licenses/index";
import {
    RefreshRight,
    Edit,
    Setting,
} from '@element-plus/icons'
import { ISortDirection } from '@/interfaces';
import { ILicenseListSort } from '@/store/settings/licenses/interfaces';
import EditLicense from "@/views/settings/licenses/modals/EditLicense.vue"
import { ElMessageBox } from 'element-plus';

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
        RefreshRight,
        Edit,
        EditLicense,
        Setting,
    },
})
export default class Licenses extends Vue {
  loading = false;
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  licenseId = null;
  licenseStatus = '';

  mounted() {
    this.init();
  }

  async init() {
    await this.getLicenseList()
  }

  async getLicenseList() {
    this.loading = true;
    await LicenseStore.getLicensesList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
    });
    this.loading = false;
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getLicenseList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getLicenseList();
  }

  handleActions(event: any) {
    if (event.name === "OpenEditLicense"){ 
      this.licenseId = event.row.id;
      this.licenseStatus = event.status;
      const modal: any = this.$refs["EditLicense"];
      modal.open();
    }
    if (event.name === "UnbindLicense") {
      ElMessageBox.confirm(
        `Вы действительно хотите отвязать от устройства лицензию "${event.row.name}"?`,
        "Внимание!",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          confirmButtonClass: "el-button--danger",
        }
      ).then(async () => {
        await LicenseStore.unbindLicense(event.row.id)
        this.getLicenseList()
      })
    }
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof ILicenseListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof ILicenseListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getLicenseList();
  }

  get licensesList() {
    return LicenseStore.licenseList;
  }
}
