
import { Options, Vue } from 'vue-class-component';
import AuthStore from "@/store/auth";

@Options({
  components: {},
  props: {
    collapse: Boolean,
  }
})
export default class Menu extends Vue {

  get isAuth() {
    return AuthStore.loggedIn;
  }

  async login() {
    await AuthStore.login();
  }

  async logout() {
    await AuthStore.logout();
  }

  get userName(): string {
    const user = Object.keys(AuthStore.userInfo).length
      ? AuthStore.userInfo.user
      : null;
    return user ? `${user.lastName} ${user.firstName}` : "Неизвестно";
  }
}
