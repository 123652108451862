
import { Options, Vue } from 'vue-class-component';
import ReceiptStore from "@/store/documentation/receipts/index";
import LicenseStore from "@/store/settings/licenses/index";
import {
    RefreshRight,
} from '@element-plus/icons'
import { ref } from 'vue'
import { ISortDirection } from '@/interfaces';
import { IReceiptListSort } from '@/store/documentation/receipts/interfaces';
import ReceiptBlock from "@/views/documentation/tickets/modals/receiptBlock.vue"

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
    dates: [],
    licenseConnectionIds: []
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
        RefreshRight,
        ReceiptBlock,
    },
})
export default class Receipts extends Vue {
  loading = false;
  showFilters = false;
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  receiptId = null
  openСollapse = []

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  additionalColums = {
    licenseConnectionId: false,
  };

  mounted() {
    this.init();
  }

  async init() {
    await this.getReceiptList()
  }

  async getReceiptList() {
    this.loading = true;
    await ReceiptStore.getReceiptsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getReceiptList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getReceiptList();
  }

  get receiptsList() {
    return ReceiptStore.receiptList;
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }

  findLicenseName(id: string) {
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof IReceiptListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IReceiptListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getReceiptList();
  }

  handleActions(event: any) {
    if (event.name === "OpenReceiptBlock"){ 
      this.receiptId = event.row;
      const modal: any = this.$refs["ReceiptBlock"];
      modal.open();
    }
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getReceiptList();
  }
}
