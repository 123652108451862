import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  ISmartCard,
  ISmartCardsList,
  ISmartCardType,
} from "@/store/documentation/smartCards/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "SmartCards" })
class SmartCard extends VuexModule {
  public _smartCardList= {} as ISmartCardsList;

  @Mutation
    public SET_DRIVER_LIST(data: ISmartCardsList): void {
    this._smartCardList = data;
  }
  
  @Action({ rawError: true })
  public async getSmartCardsList(options?: 
    IPagination & 
    ISortSearch & 
    { licenseConnectionIds: string[] }
  ) {
    const response: AxiosResponse<ISmartCardsList> =
      await instanceBackend.get(
        "/smartCards",
        { 
          params: options,
        }
      );

    this.SET_DRIVER_LIST(response.data);
    return response.data.data;
  }

  @Action({ rawError: true })
  public async getSmartCard(id: string) {
    const response: AxiosResponse<ISmartCard[]> =
      await instanceBackend.get(`/smartCards/${id}`);

    return response.data;
  }

  get smartCardList() {
    return this._smartCardList;
  }

  get smartCardType() {
    return [
      {
        id: "UMARSH_WALLET",
        name: 'кошелек "Удобный маршрут"',
      },
      {
        id: "UMARSH_LIMIT",
        name: 'лимитная "Удобный маршрут"',
      },
      {
        id: "UMARSH_LIMITLESS",
        name: 'безлимитная "Удобный маршрут"',
      }
    ] as ISmartCardType[];
  }
}
  
export default getModule(SmartCard);
  