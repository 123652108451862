import { createApp } from 'vue';
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import filters from '@/filters'

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'
import locale from "element-plus/lib/locale/lang/ru";
import VueApexCharts from "vue3-apexcharts";
import "bootstrap/scss/bootstrap.scss";
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@/assets/styles/index.scss"

import ru from 'element-plus/es/locale/lang/ru'
import 'dayjs/locale/ru'

import VueCookies from "vue3-cookies";
import { useCookies } from "vue3-cookies"; 
const { cookies } = useCookies();

const app = createApp(App)

app.config.globalProperties.$filters = filters

app
  .use(store)
  .use(router)
  .use(ElementPlus, { locale: ru, size: "small" })
  .use(VueApexCharts)
  .use(VueCookies)
  .mount('#app')
  
export default  app 
