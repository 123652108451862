import AuthStore from "@/store/auth";
import config from "@/config";
import axios from "axios";
import qs from "qs";
import { ElNotification } from "element-plus";
import { useCookies } from "vue3-cookies"; 
import router from "@/router";
const { cookies } = useCookies();

export const instanceLayer = axios.create({
  baseURL: config.layer.host,
  paramsSerializer,
});

export const instanceAms = axios.create({
  baseURL: config.ams.host,
  paramsSerializer,
});

export const instanceBackend = axios.create({
  baseURL: config.transport.host,
  paramsSerializer,
});

export const instanceBillingBackend = axios.create({
  baseURL: config.billing.host,
  paramsSerializer,
});

instanceAms.interceptors.request.use(requestHandler);
instanceAms.interceptors.response.use(responseHandler, errorHandler);

instanceBackend.interceptors.request.use(requestHandler);
instanceBackend.interceptors.response.use(responseHandler, errorHandler);

instanceBillingBackend.interceptors.request.use(requestHandler);
instanceBillingBackend.interceptors.response.use(responseHandler, errorHandler);

function paramsSerializer(params: any) {
  return qs.stringify(params, { arrayFormat: "repeat" });
}

function requestHandler(request: any) {
  request.headers = {
    authorization: `Bearer ${cookies.get("accessToken")}`,
  };
  return request;
}

function responseHandler(response: any) {
  if (response.data && response.data.message) {
    ElNotification.success({
      title: "Успешно",
      message: response.data.message,
    });
  }

  return response;
}

function errorHandler(error: any) {
  const {
    response: { status },
  } = error;

  const message =
    error.response &&
    error.response.data &&
    (error.response.data.message || error.response.data.description || error.response.data.error);

  if (status === 401) {
    AuthStore.LOGOUT();
    router.push("/");
  } else {
    ElNotification.error({
      title: "Ошибка",
      message: message || "Неизвестная ошибка",
    });
  }
}

export function NotificationSuccess(message: any) {
    ElNotification.success({
      title: "Успешно",
      message: message,
    });
}
