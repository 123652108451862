
import { Options, Vue } from 'vue-class-component';
import { Watch } from "vue-property-decorator";
import RouteStore from "@/store/references/routes/index";
import router from '@/router';

const defaultForm = JSON.stringify({
  name: "",
  typeId: "",
  paymentTypes: [],
});

@Options({})

export default class EditRoute extends Vue {
  show = false;
  form = JSON.parse(defaultForm);
  id = ''

  @Watch("$route.params.id")
  onIdChanged(id: string) {
    this.getRouteById()
    this.show = true;
    this.id = id
  } 

  mounted(){
    this.id = this.$route.params.id as string
    this.getRouteById()
    this.show = true;
  }

  async getRouteById(){
    const route = await RouteStore.getRoute(this.id);
    this.form = {... route.route ? route.route : route }
  }

  async saveRoute(){
    if(this.form.name === "") this.form.name = null
    if(this.form.type === "") this.form.type = null

    const response = await RouteStore.updateRoute({
      id: this.id, 
      params: this.form
    })
    
    if(response) this.close()
  }

  close(){
    this.refreshForm()
    router.push('/references/routes')
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }
}
