
import { Options, Vue } from 'vue-class-component';
import Menu from "@/components/Menu.vue";
import Sidebar from "@/components/Sidebar.vue"
import Footer from '@/components/FooterSidebar.vue'
import AuthStore from "@/store/auth";
import RootStore from "@/store/root";

@Options({
  components: { Menu, Sidebar, Footer },
})
export default class App extends Vue {
  public collapse = true;
  
  async mounted() {
    if (!RootStore.initState && RootStore.access) {
      await RootStore.initApp();
    }
  }  

  get isAuth() {
    return AuthStore.loggedIn;
  }
}
