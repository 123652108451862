
import { Options, Vue } from 'vue-class-component';
import SlipStore from "@/store/documentation/slips/index";
import {
    RefreshRight,
} from '@element-plus/icons'
import { ref } from 'vue'
import { ISortDirection } from '@/interfaces';
import { ISlipListSort } from '@/store/documentation/slips/interfaces';
import LicenseStore from "@/store/settings/licenses/index";
import SlipBlock from "@/views/documentation/tickets/modals/slipBlock.vue"

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
    dates: [],
    cardType: '',
    status: '',
    licenseConnectionIds: []
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      SlipBlock,
      RefreshRight,
    },
})
export default class Slips extends Vue {
  loading = false;
  showFilters = false;
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  slipId = null

  additionalColums = {
    isOnline: false,
    licenseConnectionId: false,
  };

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  mounted() {
    this.init();
  }

  async init() {
    await this.getSlipList()
  }

  async getSlipList() {
    this.loading = true;
    await SlipStore.getSlipsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getSlipList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getSlipList();
  }

  findLicenseName(id: string) {
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof ISlipListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof ISlipListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getSlipList();
  }
  
  handleActions(event: any) {
    if (event.name === "OpenSlipBlock"){ 
      this.slipId = event.row;
      const modal: any = this.$refs["SlipBlock"];
      modal.open();
    }
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getSlipList();
  }

  get slipsList() {
    return SlipStore.slipList;
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }
}
