import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend, instanceBillingBackend } from "@/plugins/axios";
import {  
  ELicenseExtensions,
  ELicenseStatus,
  ILicense,
  ILicenseListResponse,
  ILicensesConectionsList,
  ILicenseStatus,
} from "@/store/settings/licenses/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "Licenses" })
class License extends VuexModule {
  public _licenseList = {} as ILicenseListResponse;
  public _licenseConnectionsList = {} as ILicensesConectionsList;
  
  @Mutation
    public SET_LICENSE_LIST(data: ILicenseListResponse): void {
    this._licenseList = data;
  }

  @Mutation
    public SET_CONNECTIONS_DICTIONARY(data: ILicensesConectionsList): void {
    this._licenseConnectionsList = data;
  }
  
  @Action({ rawError: true })
  public async getLicensesList(options?: IPagination & ISortSearch) {
    const response: AxiosResponse<ILicenseListResponse> =
      await instanceBackend.get(
        "/licenses",
        { 
          params: options,
        }
      );

    this.SET_LICENSE_LIST(response.data);
    return response.data;
  }

  @Action({ rawError: true })
  public async getLicense(id: string) {
    const response: AxiosResponse<ILicense> =
      await instanceBackend.get(`/licenses/${id}`);

    return response.data;
  }

  @Action({ rawError: true })
  public async getLicenseBilling(id: string) {
    const response: AxiosResponse<ILicense> =
      await instanceBillingBackend.get(`/licenses/${id}`);

    return response.data;
  }

  @Action({ rawError: true })
  public async updateLicenseBilling(options: {
    id: string,
    params: {
      name?: string,
    }
  }) {
    const response: AxiosResponse<ILicense> =
      await instanceBillingBackend.patch(`/licenses/${options.id}`, options.params);

      return response
  }

  @Action({ rawError: true })
  public async updateLicenseStatus(options: {
    licenseId: string;
    body: Partial<ILicense>;
  }) {
    const response: AxiosResponse<ILicenseListResponse> =
      await instanceBillingBackend.patch(
        `/licenses/${options.licenseId}`,
        options.body
      );

      return response
  }

  @Action({ rawError: true })
  public async prolongateLicense(licenseId: string) {
    const response = await instanceBillingBackend.post(`/licenses/${licenseId}/prolongate`);

    return response
  }

  @Action({ rawError: true })
  public async deactivateLicense(licenseId: string) {
    const response = await instanceBillingBackend.post(`/licenses/${licenseId}/deactivate`);

    return response
  }

  @Action({ rawError: true })
  public async activateLicense(licenseId: string) {
    const response = await instanceBillingBackend.post(`/licenses/${licenseId}/activate`);

    return response
  }

  @Action({ rawError: true })
  public async pauseLicense(licenseId: string) {
    const response = await instanceBillingBackend.post(`/licenses/${licenseId}/pause`);

    return response
  }

  @Action({ rawError: true })
  public async unbindLicense(id: string) {
    const response = await instanceBackend.put(`/licenses/${id}/unbind`);

    return response
  }

  @Action({ rawError: true })
  public async getLicensesConectionsList() {
    const response: AxiosResponse<ILicensesConectionsList> = await instanceBackend.get(`/licenses/connections`);
    
    this.SET_CONNECTIONS_DICTIONARY(response.data);

    return response
  }

  @Action({ rawError: true })
  public async updateLicenseExtensions(body: {
    licenseId: string,
    extensions: ELicenseExtensions[]
  }) {
    const response = await instanceBackend.put(`/licenses/${body.licenseId}`, body);

    return response
  }

  get licenseList() {
    return this._licenseList;
  }

  get licenseConnectionsList() {
    return this._licenseConnectionsList;
  }

  get licenseStatuses() {
    return [
      {
        id: ELicenseStatus.ACTIVE,
        name: "Активна",
        type: 'success'
      },
      {
        id: ELicenseStatus.INACTIVE,
        name: "Неактивна",
        type: 'info'
      },
      {
        id: ELicenseStatus.PAUSED,
        name: "Приостановлена",
        type: 'warning'
      },
      {
        id: ELicenseStatus.EXPIRED,
        name: "Истекла",
        type: 'danger'
      },
    ] as ILicenseStatus[];
  }
}
  
export default getModule(License);
  