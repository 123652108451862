
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import TicketStore from "@/store/documentation/tickets/index";
import { ITicket } from '@/store/documentation/tickets/interfaces';
import { View } from '@element-plus/icons'

@Options({
  components: {
    View
  }
})
export default class TicketBlock extends Vue {
  show = false;
  ticket = {} as ITicket[]
  receiptId = null
  slipId = null
  @Prop() id!: string;

  @Watch('id')
  onIdChanged() {
    this.getTicketById()
  } 
  
  open() {
    this.show = true;
  }

  async getTicketById(){
    this.ticket = await TicketStore.getTicket(this.id);
  }
}
