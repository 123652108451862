
import { Options, Vue } from 'vue-class-component';
import { 
  Document,
  Reading,
  Setting,
  ArrowDown,
} from '@element-plus/icons'
import AuthStore from "@/store/auth";
import { IService } from '@/store/auth/interfaces';

@Options({
  components: { Document, Reading, Setting, ArrowDown, },

})
export default class Footer extends Vue {
  openСollapse = ''
  servicesUserList = [] as IService[]

  mounted() {
    this.init()
  }

  async init() {
    await AuthStore.getServicesMe()
    await AuthStore.getServices()
    this.servicesUserList = this.services.filter(service => (service.clientId === 'layer.paybox-id' || service.clientId === 'layer.billing-id') && this.servicesUser.includes(service.id))
  }

  get servicesUser() {
    return AuthStore.userServicesInfo;
  }

  get services() {
    return AuthStore.servicesList;
  }

  redirectLink(link: string) {
    window.open(link, '_blank');
  }
}
