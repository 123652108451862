import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  IRoutesList,
  IRouteTypes,
  IResponseRouteTypes,
  IRoute,
  IGetRoute,
  IStopsListFromRoute,
  IUpdateParamsStops,
  IAddStopsInRoute,
  IUpdateStopsInRoute,
  IPath
} from "@/store/references/routes/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";
import { IMessage } from "@/store/references/stops/interfaces";


@Module({ dynamic: true, store, name: "Routes" })
class Route extends VuexModule {
  public _routeList= {} as IRoutesList;
  public _route = {} as IGetRoute
  public _routeTypes= {} as IRouteTypes[];

  @Mutation
    public SET_ROUTE_LIST(data: IRoutesList): void {
    this._routeList = data;
  }

  @Mutation
    public SET_ROUTE(data: IGetRoute): void {
      this._route = data;
  }

  @Mutation
    public SET_ROUTE_TYPES(data: IRouteTypes[]): void {
    this._routeTypes = data;
  }
  
  @Action({ rawError: true })
  public async getRoutesList(options?: IPagination) {
    const response: AxiosResponse<IRoutesList> =
      await instanceBackend.get(
        "/routes",
        { 
          params: options,
        }
      );

    this.SET_ROUTE_LIST(response.data);
    return response.data;
  }

  @Action({ rawError: true })
  public async getRoute(id: string) {
    const response: AxiosResponse<IGetRoute> =
      await instanceBackend.get(`/routes/${id}`);

    this.SET_ROUTE(response.data);
    return response.data  
  }

  @Action({ rawError: true })
  public async getRouteOptions(params: IPagination & ISortSearch & {
    routeId: string,
    paymentTypeId?: string[] | string
  },
  ) {
    const response: AxiosResponse<IRoutesList> =
      await instanceBackend.get(
        `/options`,
        { 
          params,
        }
      );

    return response.data  
  }

  @Action({ rawError: true })
  public async getRouteTypes(){
    const response: AxiosResponse<IResponseRouteTypes> =
      await instanceBackend.get("/routeTypes");

    this.SET_ROUTE_TYPES(response.data.data);  
  }

  @Action({ rawError: true })
  public async addRouteOptions(options: {
    id: string,
    params: {
      name: string,
      paymentTypeId: string,
      cost: number,
      color: string
    }
  }) {
    const response: AxiosResponse<IMessage> =
      await instanceBackend.post(`/routes/${options.id}/options`, options.params);

      return response
  }

  @Action({ rawError: true })
  public async updateRouteOptions(options: {
    routeId: string,
    optionsId: string,
    params: {
      name?: string,
      paymentTypeId: string,
      cost: string,
      color: string
    }
  }) {
    const response: AxiosResponse<IMessage> =
      await instanceBackend.put(
        `/routes/${options.routeId}/options/${options.optionsId}`,
        options.params
      );

      return response
  }

  @Action({ rawError: true })
  public async deleteRouteOptions(options: {
    routeId: string,
    optionsId: string,
  }) {
    const response: AxiosResponse<IMessage> =
      await instanceBackend.delete(`/routes/${options.routeId}/options/${options.optionsId}`);

      return response
  }

  @Action({ rawError: true })
  public async addRoute(options?: {
    name: string | null,
    typeId: string | null,
    paymentTypes: string[]
  }) {
    const response:  AxiosResponse<IRoute> =
      await instanceBackend.post("/routes", options);

      this.getRoutesList()
      return response
  }

  @Action({ rawError: true })
  public async deleteRoute(id:string) {
    const response: AxiosResponse<IRoute> =
      await instanceBackend.delete(`/routes/${id}`);

      return response
  }

  @Action({ rawError: true })
  public async updateRoute(options: {
    id: string,
    params: {
      name: string,
      type: string,
      paymentTypes: string[],
    }
  }) {
    const response: AxiosResponse<IRoute> =
      await instanceBackend.put(`/routes/${options.id}`, options.params);

      this.getRoutesList()
      return response
  }

  @Action({ rawError: true })
  public async getStopsListFromRoute(id: string){
    const response: AxiosResponse<IStopsListFromRoute> =
      await instanceBackend.get(`/routes/${id}/stops`);
    
    return response.data.stops
  }

  @Action({ rawError: true })
  public async fullUpdateStopsList(options: IUpdateParamsStops){
    const response: AxiosResponse<IAddStopsInRoute> =
      await instanceBackend.post(`/routes/${options.id}/stops/full`, options.params); 

    return response.data  
  }

  @Action({ rawError: true })
  public async addStopsList(options: IUpdateParamsStops){
    const response: AxiosResponse<IAddStopsInRoute> =
      await instanceBackend.post(`/routes/${options.id}/stops`, options.params);

    return response 
  }

  @Action({ rawError: true })
  public async deleteStopsList(options: IUpdateParamsStops){
    await instanceBackend.put(`/routes/${options.id}/stops/delete`, options.params); 
  }

  @Action({ rawError: true })
  public async updateStopsList(options: IUpdateParamsStops){
    const response: AxiosResponse<IUpdateStopsInRoute> =
      await instanceBackend.patch(`/routes/${options.id}/stops`, options.params);

    return response
  }

  @Action({ rawError: true })
  public async updatePathsList(options: {
    id: string,
    params: IPath[],
    }
  ){
    const response: AxiosResponse<IUpdateStopsInRoute> =
      await instanceBackend.put(`/routes/${options.id}/paths`, options.params);

      return response
  }

  @Action({ rawError: true })
  public async editOptionsOrder(options: {
    routeId: string,
    arrayOptionsId: any[]
  }){
    const response: AxiosResponse<IUpdateStopsInRoute> =
      await instanceBackend.put(`/routes/${options.routeId}/options`, options.arrayOptionsId);

      return response
  }

  get routeTypesList() {
    return this._routeTypes;
  }

  get routeList() {
    return this._routeList;
  }

  get route() {
    return this._route;
  }  
}
  
export default getModule(Route);
  