import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  ISbpPayment,
  ISbpPaymentsList,
} from "@/store/documentation/sbpPayments/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";


@Module({ dynamic: true, store, name: "SbpPayments" })
class SbpPayment extends VuexModule {
  public _sbpPaymentList= {} as ISbpPaymentsList;

  @Mutation
    public SET_SBP_PAYMENT_LIST(data: ISbpPaymentsList): void {
    this._sbpPaymentList = data;
  }
  
  @Action({ rawError: true })
  public async getSbpPaymentsList(options?: 
    IPagination & 
    ISortSearch & 
    { licenseConnectionIds: string[] }
  ) {
    const response: AxiosResponse<ISbpPaymentsList> =
      await instanceBackend.get(
        "/sbpPayments",
        { 
          params: options,
        }
      );

    this.SET_SBP_PAYMENT_LIST(response.data);
    return response.data.data;
  }

  @Action({ rawError: true })
  public async getSbpPayment(id: string) {
    const response: AxiosResponse<ISbpPayment> =
      await instanceBackend.get(`/sbpPayments/${id}`);

    return response.data;
  }

  get sbpPaymentList() {
    return this._sbpPaymentList;
  }
}
  
export default getModule(SbpPayment);
  