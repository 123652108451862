import {
    VuexModule,
    Module,
    Mutation,
    Action,
    getModule,
} from "vuex-module-decorators";
import config from "@/config";
import { instanceAms, instanceLayer } from "@/plugins/axios";
import { IMeResponse, IService, IServicesList, IServicesMe } from "@/store/auth/interfaces";
import { TokenStorage } from "@/store/tokenStorage";
import store from "@/store";
import { AxiosResponse } from "axios";
import { ElMessageBox } from 'element-plus'
import router from "@/router";

@Module({ dynamic: true, store, name: "Auth" })
class Auth extends VuexModule {
  public user: IMeResponse = {} as IMeResponse;
  public servicesUser = [] as any[];
  public services = {} as IService[];
  public loggedIn = false;

  @Mutation
    public SET_USER(data: IMeResponse): void {
    this.loggedIn = true;
    this.user = data;
  }

  @Mutation
    public SET_SERVICES_USER(data: any[]): void {
    this.servicesUser = data;
  }

  @Mutation
    public SET_SERVICES(data: IServicesList): void {
    this.services = data.data;
  }

  @Mutation
    public LOGOUT(): void {
    TokenStorage.clear();
    this.loggedIn = false;
    this.user = {} as IMeResponse;
  }

  @Action({ rawError: true })
  public async login() {
    let resultLogin = true
    try {
      const token = TokenStorage.getAccessToken();
      if (!token) {
        const response: any = await instanceLayer.get("api/user/ctxToken");

        if (!response && !response.data) {
          resultLogin = false
          return;
        }  

        if (response?.data && response?.data === "no session") {
          window.location.href = `${config.layer.host}/api/auth/login`;
          return;
        } 
        
        TokenStorage.setAccessToken(response.data);
      }
      
      const response = await this.getMe();
      if(!response){
        resultLogin = false
        await this.showMessageLogout()
      } 
      return resultLogin
    } catch (error) {
      console.log(error);
      router.push("/");
      return false
    }
  }

  @Action({ rawError: true })
  public async showMessageLogout(){
    ElMessageBox.confirm(
      `Для использования сервиса требуется завершение текущего сеанса и подтверждение учетной записи. Продолжить?`,
      "Внимание!",
      {
        confirmButtonText: "Продолжить",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      await this.logout()
    });
  }

  @Action({ rawError: true })
  public async logout() {
    this.LOGOUT();
    window.location.href = `${config.layer.host}/api/auth/logout`;
    router.push("/");
  }

  @Action({ rawError: true })
  public async getMe() {
    try {
      const response: AxiosResponse<IMeResponse> = await instanceAms.get(
        "/accounts/me"
      );
      if (response && response.data) {
        this.SET_USER(response.data);
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  @Action({ rawError: true })
  public async getServicesMe() {
    try {
      const response: AxiosResponse<IServicesMe[]> = await instanceAms.get(
        "/services/me"
      );
      if (response && response.data) 
        this.SET_SERVICES_USER(response.data);
      
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  @Action({ rawError: true })
  public async getServices() {
    const response: AxiosResponse<IServicesList> = await instanceAms.get(
      "/services"
    );
    if (response && response.data) {
      this.SET_SERVICES(response.data);
    }
    return response;
  }

  get userInfo(): IMeResponse {
    return this.user;
  }

  get userServicesInfo() {
    return this.servicesUser;
  }

  get servicesList() {
    return this.services;
  }

  get loginState(): boolean {
    return this.loggedIn;
  }  
}

export default getModule(Auth);
