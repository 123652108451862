import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  ECashBoxType,
  EPositionType,
  EReceiptStateType,
  ICashBoxType,
  IPositionType,
  IReceipt,
  IReceiptsList,
  IReceiptStateType,
} from "@/store/documentation/receipts/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";


@Module({ dynamic: true, store, name: "Receipts" })
class Receipt extends VuexModule {
  public _receiptList= {} as IReceiptsList;
  
  @Mutation
    public SET_RECEIPT_LIST(data: IReceiptsList): void {
    this._receiptList = data;
  }
  
  @Action({ rawError: true })
  public async getReceiptsList(options?: 
    IPagination & 
    ISortSearch & 
    { licenseConnectionIds: string[] }
  ) {
    const response: AxiosResponse<IReceiptsList> =
      await instanceBackend.get(
        "/receipts",
        { 
          params: options,
        }
      );

    this.SET_RECEIPT_LIST(response.data);
    return response.data;
  }

  @Action({ rawError: true })
  public async getReceipt(id: string) {
    const response: AxiosResponse<IReceipt[]> =
      await instanceBackend.get(`/receipts/${id}`);

    return response.data;
  }

  get receiptList() {
    return this._receiptList;
  }

  get cashBoxType() {
    return [
      {
        id: ECashBoxType.VIRTUAL,
        name: 'Виртуальный',
      },
      {
        id: ECashBoxType.VD_PAY_BOX,
        name: 'Vdpaybox.ru',
      },
      {
        id: ECashBoxType.VDPAYBOX,
        name: 'Vdpaybox.ru',
      },
    ] as ICashBoxType[];
  }

  get positionType() {
    return [
      {
        id: EPositionType.SERVICE,
        name: 'Услуга',
      },
      {
        id: EPositionType.GOOD,
        name: 'Товар',
      },
    ] as IPositionType[];
  }

  get receiptStateType() {
    return [
      {
        id: EReceiptStateType.WAIT,
        name: 'Ожидает отправки',
        type: "warning",
      },
      {
        id: EReceiptStateType.PROCESSING,
        name: 'Обрабатывается',
        type: "info",
      },
      {
        id: EReceiptStateType.CANCEL,
        name: 'Отменен',
        type: "danger",
      },
      {
        id: EReceiptStateType.ERROR,
        name: 'Не удалось обработать',
        type: "danger",
      },
      {
        id: EReceiptStateType.SUCCESS,
        name: 'Успешно обработан',
        type: "success",
      },
    ] as IReceiptStateType[];
  }
}
  
export default getModule(Receipt);
  