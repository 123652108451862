
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import RouteStore from "@/store/references/routes/index";
import { IOptionsListSort, IOptionsRoute, IRoutesList } from '@/store/references/routes/interfaces';
import {
    Setting,
    Check,
    Close,
    RefreshRight,
    ArrowUp,
    ArrowDown,
} from '@element-plus/icons-vue'
import { validNumber } from '@/utils/helpers';
import { ElMessageBox } from 'element-plus';
import RootStore from "@/store/root/index";
import router from '@/router';
import { ISortDirection } from '@/interfaces';

const defaultForm = JSON.stringify({
  name: null,
  paymentTypeId: '',
  cost: null,
});

const defaultFilters = JSON.stringify({
    paymentTypeId: [],
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify('');

@Options({
  components: {
    Setting,
    Check,
    Close,
    RefreshRight,
    ArrowUp,
    ArrowDown,
  }
})
export default class OptionsRoute extends Vue {
  show = false;
  full = false
  loading = false;
  isVisibleInfo = false;
  route = {} as IRoutesList
  form = JSON.parse(defaultForm);
  editableRow = {} as IOptionsRoute;
  id = ''
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  color = '#00ff00'

  @Watch("$route.params.id")
  onIdChanged(id: string) {
    this.getRouteById()
    this.show = true;
    this.id = id
  } 

  mounted(){
    this.id = this.$route.params.id as string
    this.getRouteById()
    this.show = true;
  }

  async addRouteOptions(){
    const response = await RouteStore.addRouteOptions({
      id: this.id,
      params: {
        name: this.form.name ? this.form.name 
            : RootStore.dictionaries['paymentTypes'].map[this.form.paymentTypeId]?.name + ' - ' + this.form.cost + '₽',
        paymentTypeId: this.form.paymentTypeId,
        cost: this.form.cost,
        color: this.color
      }
    })
    if(response) {
      this.getRouteById()
      this.form = JSON.parse(defaultForm)
    }
  }

  async updateRouteOptions(){
    const response = await RouteStore.updateRouteOptions({
      routeId: this.id,
      optionsId: this.editableRow.id,
      params: {
        name: this.editableRow.name 
            ? this.editableRow.name 
            : RootStore.dictionaries['paymentTypes'].map[this.editableRow.paymentTypeId].name + ' - ' + this.editableRow.cost + '₽',
        paymentTypeId: this.editableRow.paymentTypeId,
        cost: this.editableRow.cost,
        color: this.editableRow.color,
      }
    })
    if(response) {
      this.getRouteById()
      this.form = JSON.parse(defaultForm)
      this.editableRow = {} as IOptionsRoute
    }
  }

  async deleteRouteOptions(options: IOptionsRoute){
    ElMessageBox.confirm(
      `Вы действительно хотите удалить вариант оплаты "${options.name}" ?`,
      "Внимание!",
      {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      const response = await RouteStore.deleteRouteOptions({
        routeId: this.id,
        optionsId: options.id
      })
      if(response) {
        this.getRouteById()
        this.form = JSON.parse(defaultForm)
        this.editableRow = {} as IOptionsRoute
      }
    });
  }

  async getRouteById(){
    this.loading = true
    this.route = await RouteStore.getRouteOptions({
      routeId: this.id,
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.isVisibleInfo = false;
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getRouteById();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getRouteById();
  }

  moveOption(direction: string, currOptionId: string){
    const index = this.route.data.findIndex((option) => option.id === currOptionId)
    
    if(direction === "up" && index > 0) [this.route.data[index], this.route.data[index-1]] = [this.route.data[index-1], this.route.data[index]];
 
    if(direction === "down" && index < Object.keys(this.route.data).length - 1) [this.route.data[index], this.route.data[index+1]] = [this.route.data[index+1], this.route.data[index]];

    this.isVisibleInfo = true; 
  }

  async editOptionsOrder() {
    await RouteStore.editOptionsOrder({
      routeId: this.id,
      arrayOptionsId: this.route.data.map((option) =>  ({ optionId: option.id }) )
    })
    this.getRouteById()
  }
  
  async changeSort(e: {
    column: string;
    prop: keyof IOptionsListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IOptionsListSort
    } = {
      name: 'name',
      paymentTypeId: 'paymentTypeId',
      cost: 'cost',
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getRouteById();
  }

  validate(val: string){
    this.form.cost = validNumber(val)
  }

  close(){
    router.push('/references/routes')
  }
}
