
import { Options, Vue } from 'vue-class-component';
import StopStore from "@/store/references/stops/index";
import { Prop, Watch } from 'vue-property-decorator';
import { IStop, Tree } from '@/store/references/stops/interfaces';

const defaultAddForm = JSON.stringify({
  label: '',
  type: '',
  tag: '',
});

@Options({})
export default class Create extends Vue {
  show = false;
  addForm = JSON.parse(defaultAddForm)

  @Prop() current!: Tree;
  @Prop() type!: string ;

  @Watch('type')
  onTypeChanged(val: string) {
    this.addForm.type = val
  }

  open() {
    this.show = true;
    this.addForm.type = this.type
  }
  
  async add(){
    let response: IStop[] = []
    if(this.addForm.type === 'group'){
      response = await StopStore.addStopGroup({
        name: this.addForm.label,
        parentStopGroupId: this.current?.id || null,
      })
    }
    if(this.addForm.type === 'stop'){
      response = await StopStore.addStop({
        name: this.addForm.label,
        tag: this.addForm.tag,
        stopGroupId: this.current.children ? this.current?.id || null : this.current?.stopGroupId,
      })
    }
    if(response) {
      this.handleClose()
      this.$emit("submit");
    }
  }

  handleClose(){
    this.show = false
    this.refreshForm()
  }

  refreshForm() {
    this.addForm = JSON.parse(defaultAddForm);
  }
}
