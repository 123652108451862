import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  EDocumentType,
  ETicketType,
  IDocumentType,
  ITicket,
  ITicketsList, ITicketType,
} from "@/store/documentation/tickets/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "Tickets" })
class Ticket extends VuexModule {
  public _ticketList= {} as ITicketsList;
  
  @Mutation
    public SET_TICKET_LIST(data: ITicketsList): void {
    this._ticketList = data;
  }
  
  @Action({ rawError: true })
  public async getTicketsList(options?: 
    IPagination & 
    ISortSearch & 
    { 
      paymentTypes: string[],
      licenseConnectionIds: string[] 
    }
  ) {
    const response: AxiosResponse<ITicketsList> =
      await instanceBackend.get(
        "/tickets",
        { 
          params: options,
        }
      );

    this.SET_TICKET_LIST(response.data);
    return response.data;
  }

  @Action({ rawError: true })
  public async getTicket(id: string) {
    const response: AxiosResponse<ITicket[]> =
      await instanceBackend.get(`/tickets/${id}`);

    return response.data;
  }

  get ticketList() {
    return this._ticketList;
  }
  
  get ticketType() {
    return [
      {
        id: ETicketType.STOPS,
        name: "С остановками",
      },
      {
        id: ETicketType.OPTIONS,
        name: "С фиксированными ценами",
      },
      {
        id: ETicketType.CASH,
        name: "Оплата наличными",
      },
      {
        id: ETicketType.CASHLESS,
        name: "Безналичная оплата",
      },
      {
        id: ETicketType.TRANSPORT_CARD,
        name: "Оплата транспортной картой",
      },
      {
        id: ETicketType.UMARSH_WALLET,
        name: 'Кошелек "Удобный маршрут"',
      },
      {
        id: ETicketType.UMARSH_LIMIT,
        name: 'Лимитная "Удобный маршрут"',
      },
      {
        id: ETicketType.UMARSH_LIMITLESS,
        name: 'Безлимитная "Удобный маршрут"',
      }
    ] as ITicketType[];
  }

  get documentType() {
    return [
      {
        id: EDocumentType.RECEIPT,
        name: "Чек",
      },
      {
        id: EDocumentType.SLIP,
        name: "Слип",
      },
      {
        id: EDocumentType.TICKET,
        name: "Билет",
      },
      {
        id: EDocumentType.TRANSPORT_CARD,
        name: "Транспортная карта",
      },
    ] as IDocumentType[];
  }
}
  
export default getModule(Ticket);
  