
import { Options, Vue } from 'vue-class-component';
import AuthStore from "@/store/auth";
import TabsBlock from "@/views/vdTransport/components/tabsBlock.vue"
import config from "@/config";

@Options({
  components: { TabsBlock },
})
export default class HomeView extends Vue {
  positionTab = "top"
  screenWidth = window.screen.width

  mounted(){
    this.updateWidth()
  }

  async login() {
    const response = await AuthStore.login();
    if(!response){
      window.location.href = `${config.layer.host}/api/auth/login`;
    }
  }

  async registration(){
    await AuthStore.logout()
    window.location.href = `${config.layer.host}/api/auth/login`;
  }
  
  created() {
    window.addEventListener('resize', this.updateWidth);
  }

  updateWidth() {
    this.screenWidth = window.innerWidth;
    this.screenWidth < 700 
      ? this.positionTab = "right"
      : this.positionTab = "top"
  }

  get isAuth() {
    return AuthStore.loggedIn;
  }
}
