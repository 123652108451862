
import { ref } from 'vue'
import { Options, Vue } from 'vue-class-component';
import SbpStore from "@/store/references/sbp/index";
import router from '@/router';
import UploadInstance from 'element-plus'

const defaultForm = JSON.stringify({
  name: null,
  typeId: "",
  merchantId: null,
  secretKey: null,
  clientId: null,
  clientSecret: null,
  certPassword: null,
  terminalId: null,
});

@Options({})

export default class EditSbp extends Vue {
  show = false;
  form = JSON.parse(defaultForm);
  upload: any = ref<typeof UploadInstance>()
  id = '' 

  mounted(){
    this.id = this.$route.params.id as string
    this.getSbpById()
    this.show = true;
  }

  async getSbpById(){
    const sbp = await SbpStore.getSbp(this.$route.params.id as string);
    this.form = { ...sbp }
  }

  async getSbpList() {
    await SbpStore.getSbpList({
      page: Number(this.$route.query.page) || 1,
      limit: Number(this.$route.query.limit) || 10,
    });
  }

  async saveSbp(){
    if(this.$route.params.id) {
      await SbpStore.updateSbp({
        id: this.$route.params.id as string, 
        params: {
          name: this.form.name,
          typeId: this.form.typeId,
          connectionParams: {
            secretKey: this.form.secretKey,
            merchantId: this.form.merchantId,
            clientId: this.form.clientId,
            clientSecret: this.form.clientSecret,
            certPassword: this.form.certPassword,
            terminalId: this.form.terminalId,
          }
        }
      })
    }
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }

  close(){
    this.show = false;
    this.getSbpList()
    this.refreshForm()
    router.push('/references/sbp')
  }

  async attachCertificate() {
    const file = (Object.assign({}, this.upload?.uploadFiles[0]))?.raw
    if(!file) return
    const certificate = new FormData();
    certificate.append("cert", file, file.name);
    await SbpStore.attachCertificate({
      id: this.$route.params.id as string, 
      cert: certificate
    })
  }
}
