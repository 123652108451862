
import { Options, Vue } from 'vue-class-component';
import DriverStore from "@/store/references/drivers/index";

const defaultForm = JSON.stringify({
  name: null,
  phone: null,
  birthDate: null,
});

@Options({})
export default class CreateDriver extends Vue {
  show = false;
  form = JSON.parse(defaultForm);

  open() {
    this.show = true;
  }

  async addDriver(){
    if(this.form.phone === '') this.form.phone = null
    if(this.form.birthDate === '') this.form.birthDate = null
    const response = await DriverStore.addDriver(this.form)
    if(response){
      this.$emit("submit");
      this.show = false;
      this.refreshForm()
    }
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }
}
