import config from "@/config";
import { useCookies } from "vue3-cookies"; 
const { cookies } = useCookies();

export class TokenStorage {
  public static isAuthenticated(): boolean {
    return !!TokenStorage.getAccessToken();
  }

  public static clear(): void {
    cookies.remove(config.tokens.access);
  }

  public static getAccessToken(): any {
    return cookies.get(config.tokens.access);
  }

  public static setAccessToken(token: string): void {
    cookies.set(config.tokens.access, token);
  }
}
