
import { Options, Vue } from 'vue-class-component';
import { Watch, Prop } from "vue-property-decorator";
import VehiclStore from "@/store/references/vehicles/index";
import { IVehicle } from "@/store/references/vehicles/interfaces";
import { validVehicleNumber } from '@/utils/helpers';

const defaultForm = JSON.stringify({
  licensePlate:  null,
  model:  null,
});

@Options({})

export default class EditVehicl extends Vue {
  show = false;
  form = JSON.parse(defaultForm);

  @Prop() vehicl!: IVehicle;

  @Watch('vehicl')
  onVehiclChanged(val: IVehicle) {
    this.form = {...val}
  } 

  open() {
    this.show = true;
  }

  async saveVehicl(){
    if(this.form.model === "") this.form.model = null
    if(this.form.licensePlate === "") this.form.licensePlate = null

    const response = await VehiclStore.updateVehicl({
      id: this.vehicl.id, 
      params: this.form
    })
    
    if(response){
      this.$emit("submit");
      this.show = false;
      this.refreshForm()
    }
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }

  validate(val: string){
    this.form.licensePlate = validVehicleNumber(val)
  }
}
