import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend, NotificationSuccess } from "@/plugins/axios";
import {  
  IVehiclesList,
  IVehicle,
} from "@/store/references/vehicles/interfaces"
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "Vehicle" })
class Vehicle extends VuexModule {
  public _vehiclList= {} as IVehiclesList;

  @Mutation
    public SET_VEHICL_LIST(data: IVehiclesList): void {
    this._vehiclList = data;
  }
  
  @Action({ rawError: true })
  public async getVehiclesList(options?: IPagination & ISortSearch) {
    const response: AxiosResponse<IVehiclesList> =
      await instanceBackend.get(
        "/vehicles",
        { 
          params: options,
        }
      );

    this.SET_VEHICL_LIST(response.data);
    return response.data.data;
  }

  @Action({ rawError: true })
  public async addVehicl(options?: {
    name: string | null,
    phone: string | null,
    birthDate: string | null,
  }) {
    const response: AxiosResponse<IVehicle> =
      await instanceBackend.post("/vehicles", options);

      return response
  }

  @Action({ rawError: true })
  public async deleteVehicl(id:string) {
    const response: AxiosResponse<IVehicle> =
      await instanceBackend.delete(`/vehicles/${id}`);

      return response
  }

  @Action({ rawError: true })
  public async updateVehicl(options: {
    id: string,
    params: {
      name: string,
      phone: string,
      birthDate: string,
    }
  }) {
    const response: AxiosResponse<IVehicle> =
      await instanceBackend.put(`/vehicles/${options.id}`, options.params);

      return response
  }

  get vehiclList() {
    return this._vehiclList;
  }
}
  
export default getModule(Vehicle);
  