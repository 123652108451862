import RootStore from "@/store/root";
import { TranslateType } from "@/interfaces";

export const validNumber = (val: string) => {
  val = val.replace(/[^\d.,]/g, '') //разрешены только цифры и точка
  if(val.split(/[.,]/)?.[1]?.length > 2) val = val.slice(0, -1) //два знака после разделителя
  if(val[0] === '0') val = val.substring(1) //удалить первый незначащий ноль от числа
  return val
}

export const validVehicleNumber = (val: string) => {
  val = val.toUpperCase() //капс
  val = val.replace(/[^\dАВЕКМНОРСТУХABEKMHOPCTYX]/g, '') //цифры + буквы в русской/английской раскладке 
  return val
}

export const updateQuery = (vue: any, obj: any) => {
  vue.$router.replace({
    query: { ...vue.$route.query, ...obj },
  });
};

export const translate = (key: TranslateType, id: string) => {
  const info = RootStore.dictionaries[key]?.['map'];
  return (
    (info && info[id as any] && info[id]['name']) ||
    'Неизвестно'
  )
};
 