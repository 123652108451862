import moment from "moment";
import RootStore from "@/store/root";
import AuthStore from "@/store/auth";
import { TranslateType } from "@/interfaces";

const typesMap: { [key in TranslateType]: string } = {
  licenceStatuses: "name",
  regRequestStatuses: "name",
  ticketType: "name",
  smartCardType: "name",
  acquiringType: "name",
  slipStateType: "name",
  cashBoxType: "name",
  positionType: "name",
  receiptStateType: "name",
  documentType: "name",
  routeTypes: "name",
  paymentTypes: "name",
  paymentTypesVal: "name",
  groupList: "name",
  shiftType: "name",
  sbpTypes: "name",
  cardTypes: "name",
  slipStatuses: "name",
};

const filters = {
    formatDate(date: string, format = "DD-MM-YYYY HH:mm:ss") {
      if (!date) return "-";
      return moment(date).format(format);
    },

    dictionary( key: TranslateType ) {
      return RootStore.dictionaries[key]?.['list'];
    },

    translate(params: {
      key: TranslateType,
      id: string | number,
      field?: string
    }) {
      const notFoundString = params.field ? "" : params.id;
      
      if (!typesMap[params.key]) return notFoundString;

      const info: { [key in string | number]: any } = RootStore.dictionaries[params.key]?.['map'];

      return (
        (info && info[params.id as any] && info[params.id][params.field ? params.field : typesMap[params.key]]) ||
        notFoundString
      );
    },

    access(rights: string[]) {
      if (!rights || !rights.length) return true;
    
      const myRights = AuthStore.userInfo.rights;
      const myRoles = AuthStore.userInfo.roles;
    
      if (myRoles?.includes("SUPER_ADMIN")) return true;
    
      let check = rights?.some((right) => myRights?.includes(right));
    
      if (!check) {
        check = rights?.some((right) => myRoles?.includes(right));
      }
      
      return check;
    }
}

export default filters