
import { Options, Vue } from 'vue-class-component';
import { 
  Document,
  Reading,
  Setting,
  Expand,
  Fold,
  Right,
} from '@element-plus/icons'
import AuthStore from "@/store/auth";
import { IService } from '@/store/auth/interfaces';

@Options({
  components: { 
    Document, 
    Reading, 
    Setting, 
    Expand, 
    Fold, 
    Right,
  },
  props: {
    collapse: Boolean,
  }
})
export default class Sidebar extends Vue {
  collapse = true
  servicesUserList = [] as IService[]
  clientIds = ['layer.paybox-id', 'layer.billing-id']

  mounted() {
    this.init()
  }

  async init() {
    await AuthStore.getServicesMe()
    await AuthStore.getServices()
    this.servicesUserList = this.services.filter(service => (this.clientIds.includes(service.clientId)) && (this.servicesUser.includes(service.id) || this.servicesUser[0] === null))
  }

  get servicesUser() {
    return AuthStore.userServicesInfo;
  }

  get services() {
    return AuthStore.servicesList;
  }

  redirectLink(link: string) {
    window.open(link + '/api/auth/login', '_blank');
  }
}
