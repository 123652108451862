
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import SmartCardStore from "@/store/documentation/smartCards/index";
import { View } from '@element-plus/icons'
import TicketBlock from "@/views/documentation/tickets/modals/ticketBlock.vue"
import { ISmartCard } from '@/store/documentation/smartCards/interfaces';

@Options({
  components: {
    TicketBlock,
    View
  }
})
export default class SmartCardBlock extends Vue {
  show = false;
  smartCard = {} as ISmartCard[]
  ticketId = null
  @Prop() id!: string;

  @Watch('id')
  onIdChanged() {
    this.getSmartCardById()
  } 
  
  open() {
    this.show = true;
  }

  async getSmartCardById(){
    this.smartCard = await SmartCardStore.getSmartCard(this.id);
  }

  handleActions(event: any) {
    if (event.name === "OpenTicketBlock"){ 
      this.ticketId = event.row;
      const modal: any = this.$refs["TicketBlock"];
      modal.open();
    }
  }
}
