import { IPaginationResponse, ISortDirection } from "@/interfaces";

export interface IReceiptsList extends IPaginationResponse {
  data: IReceipt[];
}

export interface IReceipt {
  id: string,
  serial: number,
  shift: number,
  stateHistory: [
    {
      type: string,
      setAt: string,
      statusUrl?: string,
      fiscalData?: {
        fd: string,
        fn: string,
        fpd: string,
        kkt: string,
        dateTime: string
      }
    }
  ],
  cashboxType: string,
  accountId: number,
  payments: [
    {
      type: string,
      amount: string
    }
  ],
  positions: [
    {
      name: string,
      type: string,
      price: string,
      quantity: string
    }
  ],
  licenseId: string,
  ticketId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string
}

export interface IReceiptListSort {
  createdAt: ISortDirection,
}

export enum ECashBoxType {
  VIRTUAL = "VIRTUAL",
  VD_PAY_BOX = "VD_PAY_BOX",
  VDPAYBOX = "VDPAYBOX",
}

export interface ICashBoxType {
  id: ECashBoxType;
  name: string;
}

export enum EPositionType {
  SERVICE = "SERVICE",
  GOOD = "GOOD",
}

export interface IPositionType {
  id: EPositionType;
  name: string;
}

export enum EReceiptStateType {
  WAIT = "WAIT",     
  PROCESSING = "PROCESSING",
  CANCEL = "CANCEL", 
  ERROR = "ERROR", 
  SUCCESS = "SUCCESS", 
}

export interface IReceiptStateType {
  id: EReceiptStateType;
  name: string;
  type: string
}
