
import { Options, Vue } from 'vue-class-component';
import TicketStore from "@/store/documentation/tickets/index";
import {
    ITicketListSort,
} from "@/store/documentation/tickets/interfaces";
import {
    RefreshRight,
} from '@element-plus/icons'
import { ref } from 'vue'
import TicketBlock from "@/views/documentation/tickets/modals/ticketBlock.vue"
import SlipBlock from "@/views/documentation/tickets/modals/slipBlock.vue"
import ReceiptBlock from "@/views/documentation/tickets/modals/receiptBlock.vue"
import SmartCardBlock from "@/views/documentation/tickets/modals/smartCardBlock.vue"
import { ISortDirection  } from "@/interfaces";
import moment from 'moment';
import * as XLSX from 'xlsx';
import LicenseStore from "@/store/settings/licenses/index";
import RootStore from "@/store/root";

const defaultFilters = JSON.stringify({
    dates: [],
    paymentTypes: [],
    licenseConnectionIds: [],
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
        RefreshRight,
        TicketBlock,
        SlipBlock,
        ReceiptBlock,
        SmartCardBlock,
    },
})
export default class Tickets extends Vue {
  loading = false;
  loadingAll = false;
  showFilters = false;
  ticketId = null
  receiptId = null
  slipId = null
  smartCardId = null

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  additionalColums = {
    optionName: false,
    startStopName: false,
    endStopName: false,
    stops: false,
    licenseConnectionId: false,
  };

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  options = {}

  mounted() {
    this.init();
  }

  async init() {
    this.initFilterDate()
    await this.getTicketList()
  }

  async getTicketList() {
    this.loading = true;
    await TicketStore.getTicketsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  initFilterDate() {
    this.filters.dates[0] = moment().subtract(7, 'day').utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
    this.filters.dates[1] = moment().utc().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]")
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getTicketList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getTicketList();
  }

  findLicenseName(id: string) {
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }

  async changeSort(e: {
    column: string;
    prop: keyof ITicketListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof ITicketListSort
    } = {
      routeType: "info.routeType",
      paymentType: "info.paymentType",
      optionName: "info.optionName",
      startStopName: "info.startStopName",
      endStopName: "info.endStopName",
      cost: "info.cost",
      createdAt: "createdAt",
      serial: "serial",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getTicketList();
  }

  handleActions(event: any) {
    if (event.name === "OpenTicketBlock"){ 
      this.ticketId = event.row;
      const modal: any = this.$refs["TicketBlock"];
      modal.open();
    }
    if (event.name === "OpenReceiptBlock"){ 
      this.receiptId = event.row[0].id;
      const modal: any = this.$refs["ReceiptBlock"];
      modal.open();
    }
    if (event.name === "OpenSlipBlock"){ 
      this.slipId = event.row[0].id;
      const modal: any = this.$refs["SlipBlock"];
      modal.open();
    }
    if (event.name === "OpenSmartCardBlock"){ 
      this.smartCardId = event.row[0].id;
      const modal: any = this.$refs["SmartCardBlock"];
      modal.open();
    }
  }

  get ticketsList() {
    return TicketStore.ticketList;
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getTicketList();
  }

  download() {
    this.loadingAll = true
    let table: any = [['№ билета', 'Дата создания', 'Стоимость', 'Тип оплаты']]

    this.ticketsList.data.forEach(element => {
      table.push([ 
        element.serial, 
        moment(element.createdAt).format('DD-MM-YYYY HH:mm:ss'),
        element.info.cost,  
        RootStore.dictionaries['paymentTypesVal'].map[element.info.paymentType].name,
      ])
    });

    const ws = XLSX.utils.aoa_to_sheet(table);
    ws['!cols'] = this.fitToColumn(table);

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'tickets');
    XLSX.writeFile(wb, 'Tickets_' + moment().format('YYYY-MM-DD_HH-mm') + '.xlsx');
    this.loadingAll = false
  }

  fitToColumn(arrayOfArray: any) {
    return arrayOfArray[0].map((colName: string, colIndex: number) => ({ 
      wch: Math.max(...arrayOfArray.map((cell: string[] | number[]) => cell[colIndex] ? cell[colIndex].toString().length : 0)) 
    }));
  }
}
