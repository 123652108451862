import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  IStopsList,
  IGroup,
  IStop,
  IRoutesListWithStop,
} from "@/store/references/stops/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";
import RootStore from "@/store/root";


@Module({ dynamic: true, store, name: "Stops" })
class Stop extends VuexModule {
  public _stopList= {} as IStopsList;
  public _groupList = [] as IGroup[];

  @Mutation
    public SET_STOP_LIST(data: IStopsList): void {
    this._stopList = data;
  }
  
  @Action({ rawError: true })
  public async getStopsList(options?: IPagination & ISortSearch & { parentId: number }) {
    const response: AxiosResponse<IStopsList> =
      await instanceBackend.get(
        "/stops",
        { 
          params: options,
        }
      );

    this.SET_STOP_LIST(response.data);
    return response.data.data;
  }

  @Action({ rawError: true })
  public async addStop(options?: {
    name: string | null,
    tag: string | null,
    stopGroupId?: number | string | null,
  }) {
    const response: AxiosResponse<IStop[]> =
      await instanceBackend.post("/stops", options);

      return response.data
  }

  @Action({ rawError: true })
  public async deleteStop(id: string | number | null) {
    const response: AxiosResponse<IStop[]> =
      await instanceBackend.delete(`/stops/${id}`);

      return response.data
  }

  @Action({ rawError: true })
  public async getRoutesListWithCurrentStop(id: string | number | null) {
    const response: AxiosResponse<IRoutesListWithStop[]> =
      await instanceBackend.get(`/stops/${id}/routes`);

      return response.data
  }

  @Action({ rawError: true })
  public async updateStop(options: {
    id: number | string | null,
    params: {
      name?: string,
      tag?: string,
      stopGroupId?: number | string | null,
    }
  }) {
    const response: AxiosResponse<IStop> =
      await instanceBackend.put(`/stops/${options.id}`, options.params);

      return response
  }

  @Action({ rawError: true })
  public async getStopsGroups(params?: {
    includeStops?: boolean
  }) {
    const response: AxiosResponse<IGroup[]> =
      await instanceBackend.get(
        "/stopsGroups",
        {
          params: params
        }
      );

    this.createDictionaryGroup(response.data)
    
    return response.data;
  }

  @Action({ rawError: true })
  public async createDictionaryGroup(response: IGroup[]) {
    this._groupList.splice(0, this._groupList.length)
    this.initDictionaryGroup(response)

    RootStore.SET_DICTIONARIES({
      data: this._groupList,
      field: "groupList",
    });
  }

  @Action({ rawError: true })
  public async initDictionaryGroup(array: IGroup[]){
    array.forEach((child) =>{
      if(child.children && !this._groupList.includes(child)) this._groupList.push(child)
      if(child.children?.length) this.initDictionaryGroup(child.children as IGroup[])
    })
  }

  @Action({ rawError: true })
  public async addStopGroup(options?: {
    name: string | number | null,
    parentStopGroupId: number | string | null,
  }) {
    const response: AxiosResponse<IStop[]> =
      await instanceBackend.post( "/stopsGroups", options);
    
    return response.data;
  }

  @Action({ rawError: true })
  public async updateStopGroup(options: {
    id: string | number | null,
    params: {
      name?: string,
      parentStopGroupId?: number | string | null;
    }
  }) {
    const response: AxiosResponse<IStop> =
      await instanceBackend.put(`/stopsGroups/${options.id}`, options.params);

      return response
  }

  @Action({ rawError: true })
  public async deleteStopGroup(id: number | string | null) {
    const response: AxiosResponse<IStop[]> =
      await instanceBackend.delete(`/stopsGroups/${id}`);
    
    return response.data;
  }

  get stopList() {
    return this._stopList;
  }
}
  
export default getModule(Stop);
  