import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  IRegRequest,
  IRegRequestsList, IRegRequestStatus,
} from "@/store/settings/regRequests/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "RegRequests" })
class RegRequest extends VuexModule {
  public _regRequestList= {} as IRegRequestsList;
  
  @Mutation
    public SET_REGREQUEST_LIST(data: IRegRequestsList): void {
    this._regRequestList = data;
  }
  
  @Action({ rawError: true })
  public async getRegRequestsList(options?: IPagination & ISortSearch) {
    const response: AxiosResponse<IRegRequestsList> =
      await instanceBackend.get(
        "/regRequests",
        { 
          params: options,
        }
      );

    this.SET_REGREQUEST_LIST(response.data);
    return response.data;
  }

  @Action({ rawError: true })
  public async deleteRegRequest(id:string) {
    const response: AxiosResponse<IRegRequest> =
      await instanceBackend.delete(`/regRequests/${id}`);

      return response
  }

  get regRequestList() {
    return this._regRequestList;
  }

  get regRequestStatuses() {
    return [
      {
        id: "new",
        name: "Новая",
        type: "",
      },
      {
        id: "confirmed",
        name: "Подтверждена",
        type: "success",
      },
    ] as IRegRequestStatus[];
  }
}
  
export default getModule(RegRequest);
  