import { IPaginationResponse, ISortDirection } from "@/interfaces";

export interface IShiftsList extends IPaginationResponse {
  data: IShift[];
}

export interface IShift {
  id: number,
  serial: number,
  info: {
    driverName: string,
    transportModeL: string,
    transportPlate: string
  },
  closedAt: string | null,
  status: EShiftType,
  licenseConnectionId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null
}

export interface IShiftListSort {
  createdAt: ISortDirection,
  closedAt: ISortDirection,
  serial: ISortDirection,
  status: ISortDirection,
}

export enum EShiftType {
  OPEN = "open",
  CLOSED = "closed",
}

export interface IShiftType {
  id: EShiftType;
  name: string;
  type?: string
}
