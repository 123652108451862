import { ISlip } from "@/store/documentation/slips/interfaces"
import { IReceipt } from "@/store/documentation/receipts/interfaces"
import { IPaginationResponse, ISortDirection } from "@/interfaces";

export interface ITicketsList extends IPaginationResponse {
  data: ITicket[];
  sum: number,
}

export interface ITicket {
  id: string,
  serial: number,
  shift: number,
  info: {
    cost: string,
    type: string,
    endStopName: string,
    startStopName: string,
    optionName: string,
    paymentType: string,
    routeType: string,
  },
  documents: {
    slipSerial: number,
    receiptSerial: number
  },
  accountId: number,
  licenseId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string,
  receipts: IReceipt,
  slips:ISlip,
}

export interface ITicketListSort {
  "info.routeType": ISortDirection,
  "info.paymentType": ISortDirection,
  "info.optionName": ISortDirection,
  'info.startStopName': ISortDirection,
  'info.endStopName': ISortDirection,
  'info.cost': ISortDirection,
  createdAt: ISortDirection,
  serial: ISortDirection,
}

export enum ETicketType {
  STOPS = "STOPS",
  CASH = "CASH",
  CASHLESS = "CASHLESS",
  TRANSPORT_CARD = "TRANSPORT_CARD",
  OPTIONS = "OPTIONS",
  UMARSH_WALLET = "UMARSH_WALLET",
  UMARSH_LIMIT = "UMARSH_LIMIT",
  UMARSH_LIMITLESS = "UMARSH_LIMITLESS",
}

export enum EDocumentType {
  RECEIPT = "RECEIPT",
  SLIP = "SLIP",
  TICKET = "TICKET",
  TRANSPORT_CARD = "TRANSPORT_CARD",
}

export interface ITicketType {
  id: ETicketType;
  name: string;
}

export interface IDocumentType {
  id: EDocumentType;
  name: string;
}