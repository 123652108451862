import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "row g-0 vh-100 vw-100 flex-wrap" }
const _hoisted_3 = { class: "col-auto container-large" }
const _hoisted_4 = {
  class: "col",
  style: {"width":"50px"}
}
const _hoisted_5 = {
  key: 1,
  class: "p-3 scroll"
}
const _hoisted_6 = {
  key: 2,
  class: "content p-3 scroll container-large"
}
const _hoisted_7 = {
  key: 3,
  class: "p-3 scroll container-small",
  style: {"height":"calc(100vh - 56px - 30px)","overflow":"scroll"}
}
const _hoisted_8 = { class: "container-small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.isAuth)
          ? (_openBlock(), _createBlock(_component_Sidebar, {
              key: 0,
              collapse: _ctx.collapse
            }, null, 8, ["collapse"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.isAuth)
          ? (_openBlock(), _createBlock(_component_Menu, {
              key: 0,
              onCollapseSidebar: _cache[0] || (_cache[0] = ($event: any) => (_ctx.collapse = !_ctx.collapse)),
              collapse: _ctx.collapse
            }, null, 8, ["collapse"]))
          : _createCommentVNode("", true),
        (!_ctx.isAuth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_router_view)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAuth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_router_view)
            ]))
          : _createCommentVNode("", true),
        (_ctx.isAuth)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_router_view)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.isAuth)
        ? (_openBlock(), _createBlock(_component_Footer, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}