
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue'
import SbpPaymentStore from "@/store/documentation/sbpPayments/index";
import TicketBlock from "@/views/documentation/tickets/modals/ticketBlock.vue"
import SbpPaymentBlock from "@/views/documentation/tickets/modals/sbpPaymentBlock.vue"
import {
    RefreshRight,
    Setting,
    Plus,
    Search,
} from '@element-plus/icons-vue'
import LicenseStore from "@/store/settings/licenses/index";
import { ISbpPayment } from '@/store/documentation/sbpPayments/interfaces';

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
    dates: [],
    licenseConnectionIds: []
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      RefreshRight,
      TicketBlock,
      SbpPaymentBlock,
      Setting,
      Plus,
      Search
    },
})
export default class SbpPayments extends Vue {
  loading = false;
  showFilters = false;
  ticketId: string | null = ''
  sbpPaymentId = ''
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  additionalColums = {
    licenseConnectionId: false,
  };

  mounted() {
    this.init();
  }

  async init() {
    await this.getSbpPaymentList()
  }

  async getSbpPaymentList() {
    this.loading = true;
    await SbpPaymentStore.getSbpPaymentsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getSbpPaymentList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    this.getSbpPaymentList();
  }

  get sbpPaymentsList() {
    return SbpPaymentStore.sbpPaymentList;
  }

  handleActions(event: {
    name: string,
    row: ISbpPayment
  }) {
    if (event.name === "OpenTicketBlock"){ 
      this.ticketId = event.row.ticketId;
      const modal: any = this.$refs["TicketBlock"];
      modal.open();
    }
    if (event.name === "OpenSbpPayment"){ 
      this.sbpPaymentId = event.row.id;
      const modal: any = this.$refs["SbpPaymentBlock"];
      modal.open();
    }
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }

  findLicenseName(id: string) {
    // console.log(this.connections.connections.data.find(connect => connect.id === id)!.licenseId)
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getSbpPaymentList();
  }
}
