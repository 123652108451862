
import { Options, Vue } from 'vue-class-component';
import AnalyticStore from "@/store/analytics/index";
import TicketStore from "@/store/documentation/tickets/index";
import { ref } from 'vue'
import {
    RefreshRight,
} from '@element-plus/icons'
import moment from 'moment';

@Options({
  components: {
    RefreshRight
  },
})
export default class Analytics extends Vue {
  colors = ['#0a5f38d9', '#186c4aad', '#05c577ad', '#2e8b57c9', '#4fff00c7', '#74ffc7f0', '#285a0eb8', '#50935de3', '#a8ff1dad', '#74a792']
  date = [] as string[]
  renderKey = 0
  loading = true
  totalPaymentType = 0
  totalDevice = 0

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  mounted() {
    this.loading = true
    this.momentDate()
    this.init()
  }

  async init() {
    const response = await this.initDaysOfWeek()
    if (response) {
      this.initPaymentType()
      this.initHourInterval()
      this.initDevice()
      this.renderKey++
    }
    this.loading = false
  }

  async initDevice() {
    await AnalyticStore.getAnalyticsDevice({
      dates: this.date
    })

    this.chartOptionsDevice.xaxis.categories 
      = this.chartOptionsDevice.labels 
      = this.analyticDevice.summary.map(series => (this.analyticDevice.billingLicenses[series.connection.licenseId as any].name || series.connection.licenseId) + " (" + series.total + " ₽)")
    this.seriesDevice = this.analyticDevice.summary.map(series => Number(series.total))
    this.totalDevice = this.seriesDevice.reduce((acc, curr) => acc + curr, 0)
    this.renderKey++
  }

  async initPaymentType() {
    await AnalyticStore.getAnalyticsPaymentType({
      dates: this.date
    })

    this.chartOptionsPaymentType.xaxis.categories 
      = this.chartOptionsPaymentType.labels 
      = this.analyticPaymentType.map(series => (TicketStore.ticketType.find(type => type.id === series.paymentType)?.name || series.paymentType) + " (" + series.total + " ₽)")
    this.seriesPaymentType = this.analyticPaymentType.map(series => Number(series.total))
    this.totalPaymentType = this.seriesPaymentType.reduce((acc, curr) => acc + curr, 0)
    this.renderKey++
  }

  async initDaysOfWeek() {
    const response = await AnalyticStore.getAnalyticsDaysOfWeek({
      dates: this.date
    })

    if(!response) return false

    this.seriesDaysOfWeek[0].data = Object.values(this.analyticDaysOfWeek).map(series => Number(series.averageByDays))
    this.seriesDaysOfWeek[1].data = Object.values(this.analyticDaysOfWeek).map(series => Number(series.total))
    this.seriesDaysOfWeek[2].data = Object.values(this.analyticDaysOfWeek).map(series => Number(series.averageByCheck))
    
    this.renderKey++
    return true
  }

  async initHourInterval() {
    await AnalyticStore.getAnalyticsHourInterval({
      dates: this.date
    })

    this.seriesHourInterval[0].data = Object.values(this.analyticHourInterval).map(series => Number(series.averageByDate))
    this.seriesHourInterval[1].data = Object.values(this.analyticHourInterval).map(series => Number(series.total))
    this.seriesHourInterval[2].data = Object.values(this.analyticHourInterval).map(series => Number(series.averageByCheck))
  }

  changeDate() {
    if(this.date && this.date.length === 2){
      this.date[0] = moment(this.date[0]).startOf('day').format("YYYY-MM-DDTHH:mm:ssZ")
      this.date[1] = moment(this.date[1]).endOf('day').format("YYYY-MM-DDTHH:mm:ssZ")
      this.init()
    }
  }

  momentDate() {
    this.date[0] = moment().startOf('month').format("YYYY-MM-DDTHH:mm:ssZ")
    this.date[1] = moment().format("YYYY-MM-DDTHH:mm:ssZ")
  }

  get analyticDevice() {
    return AnalyticStore.analyticDevice
  }

  get analyticPaymentType() {
    return AnalyticStore.analyticPaymentType
  }

  get analyticDaysOfWeek() {
    return AnalyticStore.analyticDaysOfWeek
  }

  get analyticHourInterval() {
    return AnalyticStore.analyticHourInterval
  }

  seriesDevice = [0] as number[]

  chartOptionsDevice = {
    chart: {
      width: 380,
      type: 'pie',
    },
    fill: {
      colors: this.colors
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number, chart: any) {
        return chart.w.globals.initialSeries[chart.seriesIndex] + " ₽ (" + Math.round(val) + '%)';
      },
    },
    colors: this.colors,
    xaxis: {
      categories: [] as string[],
      labels: {
        useSeriesColors: true,
      },
    },
    tooltip: {
      enabled: false, 
    },
    labels: [] as string[],
  }

  seriesPaymentType = [0] as number[]
          
  chartOptionsPaymentType = {
    chart: {
      width: 380,
      type: 'pie',
    },
    fill: {
      colors: this.colors
    },
    legend: {
      position: 'bottom'
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number, chart: any) {
        return chart.w.globals.initialSeries[chart.seriesIndex] + " ₽ (" + Math.round(val) + '%)';
      },
    },
    colors: this.colors,
    xaxis: {
      categories: [] as string[],
      labels: {
        useSeriesColors: true,
      },
    },
    tooltip: {
      enabled: false, 
    },
    labels: [] as string[],
  }

  seriesDaysOfWeek = [
    {
      name: 'Среднее по дням',
      data: [] as number[]
    }, 
    {
      name: 'Всего',
      data: [] as number[]
    }, 
    {
      name: 'Среднее по чеку',
      data: [] as number[]
    }
  ]

  chartOptionsDaysOfWeek = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
    },
    fill: {
      opacity: 1,
      colors: this.colors
    },
    colors: this.colors,
  }

  seriesHourInterval = [
    {
      name: 'Среднее по дням',
      data: [] as number[]
    }, 
    {
      name: 'Всего',
      data: [] as number[]
    }, 
    {
      name: 'Среднее по чеку',
      data: [] as number[]
    }
  ]

  chartOptionsHourInterval = {
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      labels: {
        rotate: -45
      },
      categories: ['00:00-02:59', '03:00-05:59', '06:00-08:59', '09:00-11:59', '12:00-14:59', '15:00-17:59', '18:00-20:59', '21:00-23:59'],
    },
    fill: {
      opacity: 1,
      colors: this.colors
    },
    colors: this.colors,
  }
}
