
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue'
import ShiftStore from "@/store/documentation/shifts/index";
import LicenseStore from "@/store/settings/licenses/index";
import {
    RefreshRight,
    Search,
} from '@element-plus/icons-vue'
import { ISortDirection } from "@/interfaces";
import { IShiftListSort } from '@/store/documentation/shifts/interfaces';

const defaultFilters = JSON.stringify({
  page: 1,
  limit: 10,
  dates: [],
  licenseConnectionIds: []
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      RefreshRight,
      Search
    },
})
export default class Shifts extends Vue {
  loading = false;
  showFilters = false;
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  additionalColums = {
    licenseConnectionId: false,
  };

  mounted() {
    this.init();
  }

  async init() {
    await this.getShiftList()
  }

  async getShiftList() {
    this.loading = true;
    await ShiftStore.getShiftsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getShiftList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getShiftList();
  }

  get shiftsList() {
    return ShiftStore.shiftList;
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }

  findLicenseName(id: string) {
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }

  async changeSort(e: {
    column: string;
    prop: keyof IShiftListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IShiftListSort
    } = {
      createdAt: "createdAt",
      closedAt: "closedAt",
      serial: "serial",
      status: "status",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getShiftList();
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getShiftList();
  }

  closeFilters() {
    this.showFilters = false;
  }
}
