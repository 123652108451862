import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {
  EShiftType,
  IShiftsList, IShiftType,
} from "@/store/documentation/shifts/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";

@Module({ dynamic: true, store, name: "Shifts" })
class Shift extends VuexModule {
  public _shiftList= {} as IShiftsList;
  
  @Mutation
    public SET_SHIFT_LIST(data: IShiftsList): void {
    this._shiftList = data;
  }
  
  @Action({ rawError: true })
  public async getShiftsList(options?: 
    IPagination & 
    ISortSearch & 
    { licenseConnectionIds: string[] }
  ) {
    const response: AxiosResponse<IShiftsList> =
      await instanceBackend.get(
        "/shifts",
        { 
          params: options,
        }
      );

    this.SET_SHIFT_LIST(response.data);
    return response.data;
  }

  get shiftList() {
    return this._shiftList;
  }

  get shiftType() {
    return [
      {
        id: EShiftType.OPEN,
        name: 'Открыта',
        type: 'success'
      },
      {
        id: EShiftType.CLOSED,
        name: 'Закрыта',
        type: 'danger'
      },
    ] as IShiftType[];
  }
}
  
export default getModule(Shift);
  