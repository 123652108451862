import { IPaginationResponse, ISortDirection } from "@/interfaces";

export interface ILicensesList extends IPaginationResponse {
  data: ILicense[];
}

export interface ILicense {
  id: string,
  name: string,
  comment: string,
  status: string,
  isBindedToDevice: boolean,
  licenseToken: string,
  activeFrom: string,
  activeTo: string,
  secret: string,
  accountId: number,
  deviceId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt:string,
  history: ILicenseHistory[],
  licenseServiceInfo: any;
  extensions: ELicenseExtensions[],
  tcotSettings: {
    terminalId: string
  }
}

export interface ILicenseHistory {
  register_date: string,
  unregister_date: string,
  device: {
    info: {
      id: string,
      host: string,
      tags: string[],
      time: number,
      type: string,
      board: string,
      brand: string,
      model: string,
      device: string,
      display: string,
      product: string,
      version: {
        sdkInt: number,
        release: string,
        codename: string,
        incremental: string,
      },
      hardware: string,
      fingerprint: string,
      manufacturer: string,
      supportedAbis: string[],
    },
    name: string,
  }
}

export interface ILicenseListResponse {
  billingLicenses: ILicenseBilling[];
  licenses: ILicensesList,
}

export interface ILicenseBilling {
  accountId: number,
  activeFrom: string,
  activeTo: string,
  createdAt: string,
  deletedAt: string,
  id: string,
  licenseId: string,
  name: string,
  service: string,
  status: string,
  tariffId: string,
  updatedAt: string,
}

export interface ILicenseListSort {
  createdAt: ISortDirection,
}

export interface ILicensesConectionsList {
  connections: {
    data: ILicensesConectionsListData[],
    licenses: ILicensesConectionsListLicenses[],
    total: number,
    page: number,
    limit: number,
    offset: number,
  },
  billingInfo: any
}

export interface ILicensesConectionsListData  {
  id: string,
  registeredAt: string,
  expiresAt: string,
  licenseId: string,
  licenseToken: string,
  deviceId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  license: {
    id: string,
    isBindedToDevice: boolean,
    licenseToken: string,
    secret: string | null,
    accountId: number,
    deviceId: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string | null,
  },
  device: {
    id: string,
    name: string,
    model: string | null,
    info: {
      id: string,
      host: string,
      tags: string[],
      time: number,
      type: string,
      board: string,
      brand: string,
      model: string,
      device: string,
      display: string,
      product: string,
      version: {
        sdkInt: number,
        release: string,
        codename: string,
        incremental: string,
      },
      hardware: string,
      fingerprint: string,
      manufacturer: string,
      supportedAbis: string[]
    },
    licenseId: string,
    accountId: number,
    createdAt: string,
    updatedAt: string,
    deletedAt: string | null,
    deviceId: string | null,
  }
}

export interface ILicensesConectionsListLicenses  {
  id: string;
}

export enum ELicenseStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  PAUSED = "paused",
  EXPIRED = "expired",
}

export enum ELicenseExtensions {
  UMARSH_PAN_HASHES = "UMARSH_PAN_HASHES"
}

export interface ILicenseStatus {
  id: ELicenseStatus;
  name: string;
  type: string,
}
