
import { Options, Vue } from 'vue-class-component';
import SbpStore from "@/store/references/sbp/index";
import { ISbp, ISbpListSort } from "@/store/references/sbp/interfaces";
import CreateSbp from "@/views/references/sbp/modals/CreateSbp.vue";
import {
    RefreshRight,
    Setting,
    Plus,
    Search,
    CopyDocument,
    Check
} from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus';
import { ISortDirection } from "@/interfaces";
import router from '@/router';
import { updateQuery } from '@/utils/helpers';

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      CreateSbp,
      RefreshRight,
      Setting,
      Plus,
      Search,
      CopyDocument,
      Check
    },
})
export default class Sbp extends Vue {
  loading = false;
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  showSecretKeyId = ''
  copySecretKeyId = ''
  additionalColums = {
    clientId: true,
    terminalId: true,
  };

  mounted() {
    router.replace({ query: {} })  
    this.init();
  }

  async init() {
    await this.getSbpList()
  }

  async getSbpList() {
    this.loading = true;
    await SbpStore.getSbpList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
    });
    this.loading = false;
  }

  async deleteSbp(id: string){
    await SbpStore.deleteSbp(id)
    this.getSbpList()
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    updateQuery(this, this.filters);
    this.getSbpList();
  }

  async changePage(page: number) {
    this.filters.page = page;
    updateQuery(this, this.filters);
    this.getSbpList();
  }

  handleActions(event: {name: string, row: ISbp}) {
    if (event.name === "CreateSbp") 
      this.$router.push({
        path: '/references/sbp/create', query: this.filters
      })
    
    if (event.name === "OpenEditSbp")
      this.$router.push({
        path: "/references/sbp/edit/" + event.row.id, query: this.filters
      })
  }

  get sbpList() {
    return SbpStore.sbpList;
  }

  async changeSort(e: {
    column: string;
    prop: keyof ISbpListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof ISbpListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getSbpList();
  }

  async showMessage(sbp: ISbp){
    ElMessageBox.confirm(
      `Вы действительно хотите удалить СБП "${sbp.name}"?`,
      "Внимание!",
      {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      await this.deleteSbp(sbp.id)
    });
  }

  async copySecretKey(id: string, key: string) {
    this.copySecretKeyId = id
    navigator.clipboard.writeText(key)
    window.setTimeout(this.clearCopyId, 2500);
  }

  clearCopyId() {
    this.copySecretKeyId = '';
  }
}
