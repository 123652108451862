import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  IDriversList,
  IDriver,
} from "@/store/references/drivers/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";


@Module({ dynamic: true, store, name: "Drivers" })
class Driver extends VuexModule {
  public _driverList= {} as IDriversList;

  @Mutation
    public SET_DRIVER_LIST(data: IDriversList): void {
    this._driverList = data;
  }
  
  @Action({ rawError: true })
  public async getDriversList(options?: IPagination & ISortSearch) {
    const response: AxiosResponse<IDriversList> =
      await instanceBackend.get(
        "/drivers",
        { 
          params: options,
        }
      );

    this.SET_DRIVER_LIST(response.data);
    return response.data.data;
  }

  @Action({ rawError: true })
  public async addDriver(options?: {
    name: string | null,
    phone: string | null,
    birthDate: string | null,
  }) {
    const response: AxiosResponse<IDriver> =
      await instanceBackend.post("/drivers", options);

      return response
  }

  @Action({ rawError: true })
  public async deleteDriver(id:string) {
    const response: AxiosResponse<IDriver> =
      await instanceBackend.delete(`/drivers/${id}`);

      return response
  }

  @Action({ rawError: true })
  public async updateDriver(options: {
    id: string,
    params: {
      name: string,
      phone: string,
      birthDate: string,
    }
  }) {
    const response: AxiosResponse<IDriver> =
      await instanceBackend.put(`/drivers/${options.id}`, options.params);

      return response
  }

  get driverList() {
    return this._driverList;
  }
}
  
export default getModule(Driver);
  