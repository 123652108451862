import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  ISbpList,
  ISbp,
} from "@/store/references/sbp/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";


@Module({ dynamic: true, store, name: "Sbp" })
class Sbp extends VuexModule {
  public _sbpList= {} as ISbpList;

  @Mutation
    public SET_SBP_LIST(data: ISbpList): void {
    this._sbpList = data;
  }
  
  @Action({ rawError: true })
  public async getSbpList(options?: IPagination & ISortSearch) {
    const response: AxiosResponse<ISbpList> =
      await instanceBackend.get(
        "/sbp",
        { 
          params: options,
        }
      );

    this.SET_SBP_LIST(response.data);
    return response.data.data;
  }

  @Action({ rawError: true })
  public async getSbp(id: string) {
    const response: AxiosResponse<ISbp> =
      await instanceBackend.get(`/sbp/${id}`);

    return response.data  
  }

  @Action({ rawError: true })
  public async addSbp(options?: {
    name: string | null,
    typeId: string | null,
    connectionParams: {
      secretKey?: string,
      merchantId?: string,
      clientId?: string,
      clientSecret?: string,
      certPassword?: string,
      terminalId?: string,
    }
  }) {
    const response: AxiosResponse<ISbp> =
      await instanceBackend.post("/sbp", options);

      return response
  }

  @Action({ rawError: true })
  public async deleteSbp(id:string) {
    const response: AxiosResponse<ISbp> =
      await instanceBackend.delete(`/sbp/${id}`);

      return response
  }

  @Action({ rawError: true })
  public async updateSbp(options: {
    id: string,
    params: {
      name: string | null,
      typeId: string | null,
      connectionParams: {
        secretKey?: string,
        merchantId?: string,
        clientId?: string,
        clientSecret?: string,
        certPassword?: string,
        terminalId?: string,
      }
    }
  }) {
    const response: AxiosResponse<ISbp> =
      await instanceBackend.put(`/sbp/${options.id}`, options.params);

      return response
  }

  @Action({ rawError: true })
  public async attachCertificate(options: {
    id: string,
    cert: any
  }) {
    const response: AxiosResponse<ISbp> =
      await instanceBackend.post(`/sbp/${options.id}/attachCertificate`, options.cert);

      return response
  }

  get sbpList() {
    return this._sbpList;
  }
}
  
export default getModule(Sbp);
  