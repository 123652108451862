
import { Options, Vue } from 'vue-class-component';
import RouteStore from "@/store/references/routes/index";
import {
    IRoute
} from "@/store/references/routes/interfaces";
import {
    RefreshRight,
    Setting,
    Plus,
} from '@element-plus/icons-vue'
import { ElMessageBox } from 'element-plus';
import router from '@/router';

const defaultFilters = JSON.stringify({
    dates: [],
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      RefreshRight,
      Setting,
      Plus,
    },
})
export default class Route extends Vue {
  loading = false;
  showFilters = false;
  routeInfo = {} as IRoute
  additionalColums = {
    createdAt: false,
  };
  openСollapse = []

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  mounted() {
    this.init();
  }

  async init() {
    
    await this.getRouteList()
  }

  async getRouteList() {
    this.loading = true;
    await RouteStore.getRoutesList({
      page: this.filters.page,
      limit: this.filters.limit,
    });
    this.loading = false;
    this.closeFilters();
  }

  async deleteRoute(id: string){
    await RouteStore.deleteRoute(id)
    this.getRouteList()
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getRouteList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getRouteList();
  }

  handleActions(event: any) {
    if (event.name === "OpenEditRoute") 
      router.push("/references/routes/edit/" + event.row.id)
    
    if (event.name === "OpenStopsGroupModal")
      router.push("/references/routes/stops/groups/" + event.row.id)
    
    if (event.name === "OpenPriceModal") 
      router.push("/references/routes/price/" + event.row.id)
    
    if (event.name === "OpenOptionsRoute")
      router.push("/references/routes/options/" + event.row.id)
    
    if (event.name === "CreateRoute") 
      router.push("/references/routes/create")
  }

  get routesList() {
    return RouteStore.routeList;
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getRouteList();
  }

  async showMessage(route: IRoute){
    ElMessageBox.confirm(
      `Вы действительно хотите удалить маршрут "${route.name}" ?`,
      "Внимание!",
      {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      await this.deleteRoute(route.id)
    });
  }
}
