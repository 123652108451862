import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceAms, instanceBackend } from "@/plugins/axios";
import {  IDictionaries, IDictionariesResponse, IRoleTranslates } from "@/store/root/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import AuthStore from "@/store/auth";
import RouteStore from "@/store/references/routes/index";
import RegRequestStore from "@/store/settings/regRequests/index";
import LicenseStore from "@/store/settings/licenses/index";
import TicketStore from "@/store/documentation/tickets/index";
import ShiftStore from "@/store/documentation/shifts/index";
import SmartCardStore from "@/store/documentation/smartCards/index";
import SlipStore from "@/store/documentation/slips/index";
import ReceiptStore from "@/store/documentation/receipts/index";

@Module({ dynamic: true, store, name: "Root" })
class Root extends VuexModule {
  private _roleTranslates = {} as IRoleTranslates;
  private _dictionaries = {} as IDictionaries;
  private _initState = false;
  private _access = true;
  
  @Mutation
  public SET_ROLE_TRANSLATES(data: IRoleTranslates): void {
    this._roleTranslates = data;
  }

  @Mutation
  public SET_DICTIONARIES(params: {
    field: keyof IDictionaries;
    keyId?: string;
    data: IDictionaries[keyof IDictionaries]["list"];
  }): void {
    if (!params.keyId) params.keyId = "id";

    if (!this._dictionaries[params.field])
      this._dictionaries[params.field] = { list: [], map: {} };

    this._dictionaries[params.field].list = params.data;

    const map = {} as any;

    for (let index = 0; index < params.data.length; index++) {
      const element = params.data[index] as any;

      if (!map[element[params.keyId]]) map[element[params.keyId]] = element;
    }

    this._dictionaries[params.field].map = map;
  }

  @Mutation
  public SET_INIT_STATE(value: boolean): void {
    this._initState = value;
  }

  @Mutation
  public SET_ACCESS(value: boolean): void {
    this._access = value;
  }

  @Action({ rawError: true })
  public async fetchDictionaries() {
    const response: AxiosResponse<IDictionariesResponse> =
      await instanceBackend.get(`/dictionary`);

    const listDictionaryVal = ['cardTypes', 'slipStatuses']  
    
    if (response.data) {
      Object.entries(response.data).forEach(([key, value]) => {
        return this.SET_DICTIONARIES({
          data: value,
          keyId: listDictionaryVal.includes(key) ? "val" : "id",
          field: key as keyof IDictionariesResponse,
        });
      });
      Object.entries(response.data).forEach(([key, value]) => {
        if(key === 'paymentTypes') {
          return this.SET_DICTIONARIES({
            data: value,
            keyId: "val",
            field: 'paymentTypesVal',
          });
        }
      });
    }
  }

  @Action({ rawError: true })
  public async fetchRoleTranslates() {
    const response: AxiosResponse<IRoleTranslates> = await instanceAms.get(
      "/roles/translates"
    );

    if (response && response.data) {
      this.SET_ROLE_TRANSLATES(response.data);
    }
  }

  @Action({ rawError: true })
  public async initApp() {

    await AuthStore.login();

    await Promise.all([
      this.fetchRoleTranslates(),
      this.fetchDictionaries(),
      this.getDictionary()
    ]);

    this.SET_DICTIONARIES({
      data: LicenseStore.licenseStatuses,
      field: "licenceStatuses",
    });

    this.SET_DICTIONARIES({
      data: RegRequestStore.regRequestStatuses,
      field: "regRequestStatuses",
    });

    this.SET_DICTIONARIES({
      data: ShiftStore.shiftType,
      field: "shiftType",
    });

    this.SET_DICTIONARIES({
      data: TicketStore.ticketType,
      field: "ticketType",
    });

    this.SET_DICTIONARIES({
      data: SmartCardStore.smartCardType,
      field: "smartCardType",
    });

    this.SET_DICTIONARIES({
      data: SlipStore.acquiringType,
      field: "acquiringType",
    });

    this.SET_DICTIONARIES({
      data: SlipStore.slipStateType,
      field: "slipStateType",
    });

    this.SET_DICTIONARIES({
      data: ReceiptStore.cashBoxType,
      field: "cashBoxType",
    });

    this.SET_DICTIONARIES({
      data: ReceiptStore.positionType,
      field: "positionType",
    });

    this.SET_DICTIONARIES({
      data: ReceiptStore.receiptStateType,
      field: "receiptStateType",
    });

    this.SET_DICTIONARIES({
      data: TicketStore.documentType,
      field: "documentType",
    });

    this.SET_DICTIONARIES({
      data: RouteStore.routeTypesList,
      field: "routeTypes",
    });

    this.SET_INIT_STATE(true);
  }

  @Action({ rawError: true })
  public async getDictionary() {
    await RouteStore.getRouteTypes();
    await LicenseStore.getLicensesConectionsList();
  }

  get roleTranslates(): IRoleTranslates {
    return {
      SUPER_ADMIN: { key: "SUPER_ADMIN", name: "Главный администратор" },
      SUPPORT: { key: "SUPPORT", name: "Техническая поддержка" },
      ADMIN: { key: "ADMIN", name: "Администратор" },
      ...this._roleTranslates,
    };
  }

  get initState() {
    return this._initState;
  }

  get access() {
    return this._access;
  }

  get dictionaries() {
    return this._dictionaries;
  }
}

export default getModule(Root);
