import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  IAnalyticDaysOfWeek,
  IAnalyticDevice,
  IAnalyticHourInterval,
  IAnalyticPaymentType,
} from "@/store/analytics/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";

@Module({ dynamic: true, store, name: "Analytics" })
class Analytic extends VuexModule {
  public _analyticPaymentType = {} as IAnalyticPaymentType[];
  public _analyticDaysOfWeek = {} as IAnalyticDaysOfWeek[];
  public _analyticHourInterval = {} as IAnalyticHourInterval[];
  public _analyticDevice = {} as IAnalyticDevice;

  @Mutation
    public SET_ANALYTIC_PAYMENT_TYPE(data: IAnalyticPaymentType[]): void {
    this._analyticPaymentType = data;
  }

  @Mutation
    public SET_ANALYTIC_DAYS_OF_WEEK(data: IAnalyticDaysOfWeek[]): void {
    this._analyticDaysOfWeek = data;
  }

  @Mutation
    public SET_ANALYTIC_HOUR_INTERVAL(data: IAnalyticHourInterval[]): void {
    this._analyticHourInterval = data;
  }

  @Mutation
    public SET_ANALYTIC_DEVICE(data: IAnalyticDevice): void {
    this._analyticDevice = data;
  }
  
  @Action({ rawError: true })
  public async getAnalyticsPaymentType(options?:
    { dates: string[] 
  }) {
    const response: AxiosResponse<IAnalyticPaymentType[]> =
      await instanceBackend.get(
        "/statistics/paymentType",
        { 
          params: options,
        }
      );

    this.SET_ANALYTIC_PAYMENT_TYPE(response?.data);
    return response?.data;
  }

  @Action({ rawError: true })
  public async getAnalyticsDevice(options?:
    { 
      dates: string[] 
    }) {
    const response: AxiosResponse<IAnalyticDevice> =
      await instanceBackend.get(
        "/statistics/deviceId",
        { 
          params: options,
        }
      );

    this.SET_ANALYTIC_DEVICE(response?.data);
    return response?.data;
  }

  @Action({ rawError: true })
  public async getAnalyticsDaysOfWeek(options?:
    { dates: string[] 
  }) {
    const response: AxiosResponse<IAnalyticDaysOfWeek[]> =
      await instanceBackend.get(
        "/statistics/daysOfWeek",
        { 
          params: options,
        }
      )
      this.SET_ANALYTIC_DAYS_OF_WEEK(response?.data);    
    return response?.data;
  }

  @Action({ rawError: true })
  public async getAnalyticsHourInterval(options?:
    { dates?: string[] 
  }) {
    const response: AxiosResponse<IAnalyticHourInterval[]> =
      await instanceBackend.get(
        "/statistics/hourInterval",
        { 
          params: options,
        }
      )
      this.SET_ANALYTIC_HOUR_INTERVAL(response?.data);    
    return response?.data;
  }

  get analyticPaymentType() {
    return this._analyticPaymentType;
  }

  get analyticDaysOfWeek() {
    return this._analyticDaysOfWeek;
  }

  get analyticHourInterval() {
    return this._analyticHourInterval;
  }

  get analyticDevice() {
    return this._analyticDevice;
  }
}
  
export default getModule(Analytic);
  