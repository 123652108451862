
import { Options, Vue } from 'vue-class-component';
import RouteStore from "@/store/references/routes/index";
import router from '@/router';

const defaultForm = JSON.stringify({
  name: null,
  typeId: null,
  paymentTypes: [],
});

@Options({})
export default class CreateRoute extends Vue {
  show = false;
  form = JSON.parse(defaultForm);

  mounted(){
    this.show = true;
  }

  async addRoute(){
    const response = await RouteStore.addRoute(this.form)
    if(response){
      this.$emit("submit");
      this.show = false;
      this.refreshForm()
    }
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }

  close(){
    this.refreshForm()
    router.push('/references/routes')
  }
}
