
import { Options, Vue } from 'vue-class-component';
import { Watch } from "vue-property-decorator";
import RouteStore from "@/store/references/routes/index";
import { IPath, IPathByGetRoute, IStopsFromRoute } from "@/store/references/routes/interfaces";
import { validNumber } from "@/utils/helpers";
import router from '@/router';

@Options({
  components: {}
})

export default class PriceRoute extends Vue {
  show = false;
  full = false
  loading = false;
  stopsList: IStopsFromRoute[] = []
  paths: IPathByGetRoute[] = []

  @Watch("$route.params.id")
  onIdChanged(id: string) {
    if(id) this.init()
  }

  mounted() {
    this.init()
    this.show = true;
  }

  async init(){
    this.loading = true
    this.stopsList = await RouteStore.getStopsListFromRoute(this.$route.params.id as string)
    this.stopsList.push({} as IStopsFromRoute)
    await RouteStore.getRoute(this.$route.params.id as string)
    this.paths = this.currentRoute.paths
    this.stopsList.splice(this.stopsList.length-1, 1)
    this.loading = false
  }

  async updateCostPaths() {
    const updatePathsList: IPath[] = []

    Object.entries(this.paths).forEach((path) => {
      updatePathsList.push({
        startStopId: path[0].split('__')[0],
        endStopId: path[0].split('__')[1],
        cost: parseFloat(path[1].cost.replace(/,/g, '.')),
      })
    })

    const response = await RouteStore.updatePathsList({
      id: this.$route.params.id as string, 
      params: updatePathsList
    })
    if(response) this.init()
  }

  validate(val: string, id: any){
    this.paths[id].cost = validNumber(val)
  }

  get currentRoute() {
    return RouteStore.route;
  }

  close(){
    router.push('/references/routes')
  }
}
