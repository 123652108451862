
import { Options, Vue } from 'vue-class-component';
import DriverStore from "@/store/references/drivers/index";
import { IDriver, IDriverListSort } from "@/store/references/drivers/interfaces";
import {
    RefreshRight,
    Setting,
    Plus,
    Search,
} from '@element-plus/icons-vue'
import CreateDriver from "@/views/references/drivers/modals/CreateDriver.vue";
import EditDriver from "@/views/references/drivers/modals/EditDriver.vue";
import { ElMessageBox } from 'element-plus';
import { ISortDirection } from "@/interfaces";

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      CreateDriver,
      EditDriver,
      RefreshRight,
      Setting,
      Plus,
      Search
    },
})
export default class Drivers extends Vue {
  loading = false;
  driverInfo = {} as IDriver

  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  mounted() {
    this.init();
  }

  async init() {
    await this.getDriverList()
  }

  async getDriverList() {
    this.loading = true;
    await DriverStore.getDriversList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
    });
    this.loading = false;
  }

  async deleteDriver(id: string){
    await DriverStore.deleteDriver(id)
    this.getDriverList()
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getDriverList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getDriverList();
  }

  handleActions(event: {name: string, row: IDriver}) {
    if (event.name === "CreateDriver"){ 
      const modal: any = this.$refs["CreateDriver"];
      modal.open();
    }
    if (event.name === "OpenEditDriver"){ 
      this.driverInfo = event.row
      const modal: any = this.$refs["EditDriver"];
      modal.open();
    }
  }

  get driversList() {
    return DriverStore.driverList;
  }

  async changeSort(e: {
    column: string;
    prop: keyof IDriverListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IDriverListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getDriverList();
  }

  async showMessage(driver: IDriver){
    ElMessageBox.confirm(
      `Вы действительно хотите удалить водителя "${driver.name}"?`,
      "Внимание!",
      {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        confirmButtonClass: "el-button--danger",
      }
    ).then(async () => {
      await this.deleteDriver(driver.id)
    });
  }
}
