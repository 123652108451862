
import { Options, Vue } from 'vue-class-component';
import StopStore from "@/store/references/stops/index";
import { IStop, IGroup, IStopListSort, Tree } from "@/store/references/stops/interfaces";
import {
    RefreshRight,
    Delete,
    CirclePlus,
    FolderAdd,
    Edit,
    Finished,
    FolderOpened,
    Search,
    Folder,
} from '@element-plus/icons'
import Create from "@/views/references/stops/modals/Create.vue";
import Update from "@/views/references/stops/modals/Update.vue";
import { ElMessageBox } from 'element-plus';
import { DropType } from 'element-plus/es/components/tree/src/tree.type';
import { ISortDirection } from '@/interfaces';

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
    search: '',
    parentId: null
});

const defaultSorts = JSON.stringify(
  "name" + "-" + "ASC",
);

interface IDataTree {
  data: Tree
}

@Options({
  components: {
      RefreshRight,
      Delete,
      CirclePlus,
      FolderAdd,
      Edit,
      Finished,
      FolderOpened,
      Create,
      Update,
      Search,
      Folder,
    },
})


export default class StopGroup extends Vue {
  loading = false;
  includeStops = false;
  renderKey = 0
  expandAll = false
  stopGroups: IGroup[] = []
  current = {
    id: null,
    children: []
  } as Tree 
  expanded: number[] | string[] | undefined[] | null[] = [null]
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);
  typeAddElement = ""

  mounted(){
    this.current = {
      id: null,
      children: []
    } as Tree
    
    this.init();
  }

  async init() {
    this.stopGroups = await StopStore.getStopsGroups({
      includeStops: this.includeStops 
    })
    this.initStopGroup(this.stopGroups)
    this.stopGroups = [{
      id: null,
      label: "Группы",
      children: this.stopGroups
    }] as IGroup[]
    await this.getStopList()
    this.renderKey++ 
    this.loading = false;
  }

  initStopGroup(array: Tree[]){
    array.forEach((child) =>{
      child.label = child.name
      if(child.children?.length) this.initStopGroup(child.children)
    })
  }

  async remove(data: Tree){
    if(data.children){
      ElMessageBox.confirm(
        `Вы действительно хотите удалить группу "${data.label}" ?`,
        "Внимание!",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          confirmButtonClass: "el-button--danger",
        }
      ).then(async () => {
        const response = await StopStore.deleteStopGroup(data.id)
        if(response) {
          this.init()
          this.expanded[0] = data.parentStopGroupId
        }
      });
    } 
    else {
      ElMessageBox.confirm(
        `Вы действительно хотите удалить остановку "${data.name}" ?`,
        "Внимание!",
        {
          confirmButtonText: "Да",
          cancelButtonText: "Отмена",
          confirmButtonClass: "el-button--danger",
        }
      ).then(async () => {
        this.deleteStop(data)
      });
    }
  }

  async deleteStop(data: Tree) {
    const routeslist = await StopStore.getRoutesListWithCurrentStop(data.id)
    if(routeslist.length) {
      ElMessageBox.alert(
        `<p>Остановка "${data.name}" содержится в ${routeslist.length > 1 ? 'маршрутах' : 'маршруте'}:</p><p>${routeslist.map(route => route.name)}</p><p style="color: #999999">Для удаления остановки, необходимо удалить ее из всех маршрутов.</p><p><a href="#/references/routes/">Перейти в раздел маршруты</a></p>`,
        'Предупреждение',
        {
          center: true,
          dangerouslyUseHTMLString: true,
          type: 'warning',
        }
      )
    }
    else {
      const response = await StopStore.deleteStop(data.id)
      if(response) {
        this.init()
        this.expanded[0] = data.stopGroupId
      }
    }
  }

  allowDrop(draggingNode: Node, dropNode: IDataTree){ //разрешено ли перетаскивание
    if(!dropNode.data.children) return false
    else return true
  }

  async handleDragEnd( //изменение положения(принадлежности к группе)
    draggingNode: IDataTree,
    dropNode: IDataTree,
    dropType: DropType & 'none',
  ){
    if(dropType === 'none') return
    if(!dropNode.data.children) return

    let id = null
    let parent = null

    if(draggingNode.data.children){
      if(dropType === 'inner'){
        id = draggingNode.data.id
        parent = dropNode.data.id
      }
      if(dropType === 'after' || dropType ===  'before'){
        id = draggingNode.data.id
        parent = dropNode.data.stopGroupId === undefined ? dropNode.data.parentStopGroupId : dropNode.data.stopGroupId        
      }
      const response = await StopStore.updateStopGroup({
        id: id, 
        params: {
          parentStopGroupId: parent
        }
      })
      if(response){
        this.init()
        this.expandAll = true
      }
    } 
    else {
      if(dropType === 'inner'){
        id = draggingNode.data.id
        parent = dropNode.data.id
      }
      if(dropType === 'after' || dropType ===  'before'){
        id = draggingNode.data.id
        parent = dropNode.data.stopGroupId === undefined ? dropNode.data.parentStopGroupId : dropNode.data.stopGroupId        
      }
      const response = await StopStore.updateStop({
        id: id, 
        params: {
          stopGroupId: parent
        }
      })
      if(response){
        this.init()
        this.expandAll = true
      }
    }
  }

  handleNodeClick(data: Tree){
    this.current = data
    this.filters.parentId = this.current.children ? this.current.id : this.current.stopGroupId
    this.getStopList()
  }

  async getStopList() {
    await StopStore.getStopsList({
      page: this.filters.page,
      limit: this.filters.limit,
      sort: this.sorts,
      search: this.filters.search,
      parentId: this.filters.parentId,
    });
  }

  async changeSort(e: {
    column: string;
    prop: keyof IStopListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof IStopListSort
    } = {
      name: "name",
      tag: "tag",
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getStopList();
  }

  handleActions(event: any) {
    if (event.name === "add"){ 
      const modal: any = this.$refs["Create"];
      this.typeAddElement = event.type
      modal.open();
    }
    if (event.name === "update"){ 
      const modal: any = this.$refs["Update"];
      modal.open();
    }
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getStopList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getStopList();
  }

  rowClick(row: IStop){
    if(this.includeStops) {
      this.expanded[0] = row.stopGroupId
      this.renderKey++
    }
    this.current = row
  }

  get stopsList() {
    return StopStore.stopList;
  }

}
