
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { View } from '@element-plus/icons'
import SbpPaymentStore from "@/store/documentation/sbpPayments/index";
import { ISbpPayment } from '@/store/documentation/sbpPayments/interfaces';
import LicenseStore from "@/store/settings/licenses/index";

@Options({
  components: {
    View
  }
})
export default class SbpPaymentBlock extends Vue {
  show = false;
  sbpPayment = {} as ISbpPayment

  @Prop() id!: string;

  @Watch('id')
  onIdChanged() {
    this.getSbpPaymentById()
  } 
  
  open() {
    this.show = true;
  }

  async getSbpPaymentById(){
    this.sbpPayment = await SbpPaymentStore.getSbpPayment(this.id);
  }

  findLicenseName(id: string) {
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }
}
