
import { Options, Vue } from 'vue-class-component';
import VehiclStore from "@/store/references/vehicles/index";
import { validVehicleNumber } from '@/utils/helpers';

const defaultForm = JSON.stringify({
  licensePlate:  null,
  model:  null,
});

@Options({})
export default class CreateVehicl extends Vue {
  show = false;
  form = JSON.parse(defaultForm);

  open() {
    this.show = true;
  }

  async addVehicl(){
    const response = await VehiclStore.addVehicl(this.form)
    if(response){
      this.$emit("submit");
      this.show = false;
      this.refreshForm()
    }
  }

  refreshForm() {
    this.form = JSON.parse(defaultForm);
  }

  validate(val: string){
    this.form.licensePlate = validVehicleNumber(val)
  }
}
