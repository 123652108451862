
import { Options, Vue } from 'vue-class-component';
import { ref } from 'vue'
import SmartCardStore from "@/store/documentation/smartCards/index";
import { ISmartCardListSort } from "@/store/documentation/smartCards/interfaces";
import { RefreshRight } from '@element-plus/icons-vue'
import { ISortDirection } from "@/interfaces";
import SmartCardBlock from "@/views/documentation/tickets/modals/smartCardBlock.vue"
import LicenseStore from "@/store/settings/licenses/index";

const defaultFilters = JSON.stringify({
    page: 1,
    limit: 10,
    dates: [],
    licenseConnectionIds: []
});

const defaultSorts = JSON.stringify(
  "createdAt" + "-" + "DESC",
);

@Options({
    components: {
      RefreshRight,
      SmartCardBlock,
    },
})
export default class SmartCards extends Vue {
  loading = false;
  showFilters = false;
  smartCardId = null
  filters = JSON.parse(defaultFilters);
  sorts = JSON.parse(defaultSorts);

  defaultTime = ref([
    new Date(2000, 1, 1, 0, 0, 0),
    new Date(2000, 2, 1, 23, 59, 59),
  ])

  additionalColums = {
    licenseConnectionId: false,
  };

  mounted() {
    this.init();
  }

  async init() {
    await this.getSmartCardList()
  }

  async getSmartCardList() {
    this.loading = true;
    await SmartCardStore.getSmartCardsList({
      ...this.filters,
      sort: this.sorts,
    });
    this.loading = false;
    this.closeFilters();
  }

  async pageSizeChange(limit: number) {
    this.filters.limit = limit;
    this.getSmartCardList();
  }

  async changePage(page: number) {
      this.filters.page = page;
      this.getSmartCardList();
  }

  handleActions(event: any) {
    if (event.name === "OpenSmartCardBlock"){ 
      this.smartCardId = event.row;
      const modal: any = this.$refs["SmartCardBlock"];
      modal.open();
    }
  }

  get smartCardsList() {
    return SmartCardStore.smartCardList;
  }

  get connections() {
    return LicenseStore.licenseConnectionsList;
  }

  findLicenseName(id: string) {
    return this.connections.billingInfo[this.connections.connections.data.find(connect => connect.id === id)!.licenseId].name || 'Неизвестно'
  }

  async changeSort(e: {
    column: string;
    prop: keyof ISmartCardListSort;
    order: string;
  }): Promise < void > {
    this.sorts = "";

    const sortMap: {
      [key: string]: keyof ISmartCardListSort
    } = {
      createdAt: "createdAt",
    };

    if (!sortMap[e.prop]) return;

    if (e.order === null) {
      this.sorts = JSON.parse(defaultSorts)
    } else {
      const direction: ISortDirection = e.order === "descending" ? "DESC" : "ASC";
      this.sorts = sortMap[e.prop] + '-' + direction;
    }

    this.getSmartCardList();
  }

  closeFilters() {
    this.showFilters = false;
  }

  clearFilters() {
    this.filters = JSON.parse(defaultFilters);
    this.getSmartCardList();
  }
}
