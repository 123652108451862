
import { IReceipt } from '@/store/documentation/receipts/interfaces';
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { View } from '@element-plus/icons'
import ReceiptStore from "@/store/documentation/receipts/index";
import TicketBlock from "@/views/documentation/tickets/modals/ticketBlock.vue"

@Options({
  components: {
    TicketBlock,
    View,
  }
})

export default class ReceiptBlock extends Vue {
  show = false;
  receipt = {} as IReceipt[]
  ticketId = null
  @Prop() id!: string;

  @Watch('id')
  onIdChanged() {
    this.getReceiptById()
  }

  async getReceiptById(){
    this.receipt = await ReceiptStore.getReceipt(this.id);
  }

  handleActions(event: any) {
    if (event.name === "OpenTicketBlock"){ 
      this.ticketId = event.row;
      const modal: any = this.$refs["TicketBlock"];
      modal.open();
    }
  }

  open() {
    this.show = true;
  }

  async openCheck(receipt: IReceipt){
    window.open(receipt.stateHistory.find(elem => elem.type == "PROCESSING")?.statusUrl, '_blank');
  }
}
