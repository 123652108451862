import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import { instanceBackend } from "@/plugins/axios";
import {  
  IAcquiringType,
  ISlip,
  ISlipsList,
  ISlipStateType,
} from "@/store/documentation/slips/interfaces";
import store from "@/store";
import { AxiosResponse } from "axios";
import { IPagination, ISortSearch } from "@/interfaces";


@Module({ dynamic: true, store, name: "Slips" })
class Slip extends VuexModule {
  public _slipList= {} as ISlipsList;
  
  @Mutation
    public SET_SLIP_LIST(data: ISlipsList): void {
    this._slipList = data;
  }
  
  @Action({ rawError: true })
  public async getSlipsList(options?: 
    IPagination & 
    ISortSearch & 
    {
      cardType: string,
      status: string,
      licenseConnectionIds: string[]
    }) {
    const response: AxiosResponse<ISlipsList> =
      await instanceBackend.get(
        "/slips",
        { 
          params: options,
        }
      );

    this.SET_SLIP_LIST(response.data);
    return response.data;
  }

  @Action({ rawError: true })
  public async getSlip(id: string) {
    const response: AxiosResponse<ISlip[]> =
      await instanceBackend.get(`/slips/${id}`);

    return response.data;
  }

  get slipList() {
    return this._slipList;
  }

  get acquiringType() {
    return [
      {
        id: "VIRTUAL",
        name: 'Виртуальный',
      },
      {
        id: "INGENICO",
        name: 'Для устройств www.ingenico.com',
      },
    ] as IAcquiringType[];
  }

  get slipStateType() {
    return [
      {
        id: "SUCCESS",
        name: 'Обработан успешно',
        type: "success"
      },
      {
        id: "WAIT",
        name: 'Ожидает отправки',
        type: "warning",
      },
      {
        id: "FAIL",
        name: 'Не удалось обработать',
        type: "danger"
      },
    ] as ISlipStateType[];
  }
}
  
export default getModule(Slip);
  